const GermanyTrans = {
  translation : {
    mainPageLanding : {
      mainTitle: 'Abyedh-Systeme',
      adsText: {
        mainText: 'Die Digitalisierung verschiedener täglicher Geschäftsvorgänge in verschiedenen Bereichen wird zu deren Entwicklung und Verbesserung in Richtung einer besseren Qualität beitragen und damit dazu beitragen, Aufwand zu reduzieren sowie Zeit und Ressourcen zu sparen. Daher zielt die Abiad-Plattform darauf ab, das Geschäftsumfeld in Tunesien in allen Branchen durch verschiedene Verwaltungssysteme zu verbessern.',
        subText: 'Die Abiad-Plattform bietet viele Systeme in verschiedenen Bereichen:'
      },
      categoriesName: {
        ptvente: 'Verkaufspunktsysteme:',
        health: 'Gesundheitssysteme:',
        education: 'Bildungssysteme:',
        restaurant: 'Gastgewerbliche Dienstleistungssysteme:',
        transport: 'Transport- und Mobilitätsdienstleistungssysteme:',
        handmade: 'Handwerksdienstleistungssysteme:',
        construction: 'Immobilien-Dienstleistungssysteme:',
        wedding: 'Hochzeitsdienstleistungssysteme:',
        sport: 'Sportdienstleistungssysteme:',
        other: 'Andere Systeme:',
      },
      systemNames : {
          docteur: "Arztpraxis-Verwaltungssystem",
          pharmacie: "Apothekenverwaltungssystem",
          clinique: "Verwaltungssystem für private Kliniken",
          labo: "Verwaltungssystem für medizinische Labore",
          infirmier: "Verwaltungssystem für Pflegekliniken",
          centre: "Verwaltungssystem für medizinische Zentren",
          admin_s_hospital: "Krankenhausverwaltungssystem",
          admin_s_csb: "Verwaltungssystem für Gesundheitszentren",
          garderie: "Verwaltungssystem für Kindertagesstätten",
          ecole: "Verwaltungssystem für private Schulen",
          lycee: "Verwaltungssystem für private Gymnasien",
          universite: "Verwaltungssystem für private Universitäten",
          formation: "Verwaltungssystem für spezialisierte Ausbildungszentren",
          impremerie: "Druckereiverwaltungssystem",
          librairie: "Buchhandlungsverwaltungssystem",
          admin_e_centre: "Verwaltungssystem für Ausbildungszentren",
          admin_e_biblio: "Verwaltungssystem für öffentliche Bibliotheken",
          admin_e_ecole: "Verwaltungssystem für öffentliche Schulen",
          admin_e_lycee: "Verwaltungssystem für öffentliche Gymnasien",
          admin_e_universite: "Verwaltungssystem für öffentliche Universitäten",
          admin_e_ss: "Verwaltungssystem für öffentliche Dienstleistungen",
          transporteur: "Transportagenturverwaltungssystem",
          autoecole: "Fahrschulverwaltungssystem",
          cafe: "Caféverwaltungssystem",
          restaurant: "Restaurantverwaltungssystem",
          magazin: "Geschäftsverwaltungssystem",
          boulengerie: "Bäckereiverwaltungssystem",
          boucherie: "Metzgereiverwaltungssystem",
          fruiterie: "Obst- und Gemüseverwaltungssystem",
          patesserie: "Konditoreiverwaltungssystem",
          epicerie: "Lebensmittelgeschäftverwaltungssystem",
          electromenager: "Verwaltungssystem für den Verkauf von Haushaltsgeräten",
          meublerie: "Möbelgeschäftverwaltungssystem",
          location: "Autovermietungsverwaltungssystem",
          parking: "Parkplatzverwaltungssystem",
          qiosque: "Tankstellenverwaltungssystem",
          mecanicien: "Kfz-Werkstattverwaltungssystem",
          courtier: "Immobilienagenturverwaltungssystem",
          contracteur: "Auftragnehmerverwaltungssystem",
          architecture: "Architekturbüroverwaltungssystem",
          quincaillerie: "Eisenwarengeschäftverwaltungssystem",
          forgeron: "Schmiedeverwaltungssystem",
          menuisier: "Schreinereiverwaltungssystem",
          peinture: "Malerunternehmenverwaltungssystem",
          electricien: "Elektriker-Serviceverwaltungssystem",
          plombier: "Sanitärinstallateur-Serviceverwaltungssystem",
          cristalerie: "Glasereiverwaltungssystem",
          marbrerie: "Marmor- und Steinmetzbetrieb-Verwaltungssystem",
          coiffure: "Friseursalonverwaltungssystem",
          boutique: "Boutiqueverwaltungssystem",
          salle_marriage: "Hochzeitssaalverwaltungssystem",
          orchestre: "Orchester-Verwaltungssystem",
          chef: "Küchenchef-Terminverwaltungssystem",
          photographe: "Fotostudioverwaltungssystem",
          fourniture_marriage: "Hochzeitsausstattungsverleihverwaltungssystem",
          bijouterie: "Schmuckgeschäftverwaltungssystem",
          gym: "Fitnessstudioverwaltungssystem",
          pyscine: "Schwimmbadverwaltungssystem",
          stade: "Stadionverwaltungssystem",
          cinema: "Kino-Verwaltungssystem",
          theatre: "Theaterverwaltungssystem",
          musee: "Museumverwaltungssystem",
          admin_c_mj: "Jugendzentrumverwaltungssystem",
          admin_c_mc: "Kulturzentrumverwaltungssystem",
          admin_c_me: "Kinderclub-Verwaltungssystem",
          avocat: "Anwaltskanzlei-Verwaltungssystem",
          depot: "Großhandelspunktverwaltungssystem",
          comptable: "Steuerberatungsbüroverwaltungssystem",
          socite: "Unternehmensverwaltungssystem",
          bank: "Bankverwaltungssystem",
          admin_f_poste: "Poststellenverwaltungssystem",
          admin_f_rf: "Finanzverwaltungssystem",
          veterinaire: "Tierarztpraxis-Verwaltungssystem",
          fourragerie: "Tierfutterverkaufsstellenverwaltungssystem",
          agritools: "Verwaltungssystem für den Verkauf von landwirtschaftlichen Geräten",
          farmer: "Verwaltungssystem für den Verkauf landwirtschaftlicher Produkte",
          hotels: "Hotelverwaltungssystem",
          vg_agence: "Reisebüroverwaltungssystem",
          admin_a_mu: "Musikschulverwaltungssystem",
          admin_a_police: "Polizeiwachenverwaltungssystem",
          admin_a_ar: "Feuerwachenverwaltungssystem",
          admin_a_court: "Gerichtsverwaltungssystem",
          admin_a_mosq: "Moscheeverwaltungssystem",
          agriToolsOld: "Verwaltungssystem für landwirtschaftliche Gerätegeschäfte"
      },
    },
    systemLandingPage:{
      adsText: {
        depot: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم .  كما يقدم لك واجهة رسومية (caisse) لتسجيل مبيعات الشاحنات و طباعة الفواتير.  بالإضافة إلي مجموعة من الأدوات  (مثل واجهة  تسجيل الطلبات    commandes  )  التي تساعدك علي أداء عملك بجودة أفضل',
        magazin: 'نظام إداراة المغازاة و نقاط البيع يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        fruiterie: 'نظام إداراة نقاط  بيع الخضر  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        boucherie: 'نظام إداراة المخابز  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        boulengerie: 'نظام إداراة نقاط بيع اللحوم  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        patesserie: 'نظام إداراة نقاط بيع الحلويات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        epicerie: 'نظام إداراة البقالات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        pharmacie: 'نظام إداراة نقاط الصيدليات يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        librairie: 'نظام إداراة الكتبيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        boutique: 'نظام إداراة نقاط بيع الملابس  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        quincaillerie: 'نظام إداراة Quancaillerie  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        electromenager: 'نظام إداراة نقاط بيع الالكترونيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        meublerie: 'نظام إداراة نقاط ابيع الاثاث  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        docteur: ' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما متصلا مع مرضاك  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
        infirmier: ' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما بإتصال بالمرضي  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
        clinique: 'نظام إدارة المصحات يساعدك علي تتبع جميع العمليات و الأنشطة التي تحدث داخل مصحتك . كما يساعدك علي متابعة مواعيد العمليات و تقارير يومية لفهم تطور الحالة الصحية للمرضي, بالإضافة لجملة من الأدوات الرقمية مثل واجهة تسجيل حضور فريق العمل و واجهة متابعة مريض معين  ',
        labo: 'يساعدك نظام إدارة المخابر الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
        centre: 'يساعدك نظام إدارة المراكز الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
        garderie: 'نظام إدارة  رياض الأطفال يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
        autoecole: 'يساعدك نظام إدارة    مدارس تعليم السياقة علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
        ecole: 'نظام إدارة المدارس الخاصة يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
        formation: 'نظام إدارة  مراكز التكوين   يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
        lycee: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        universite: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        restaurant: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        cafe: 'نكانك صاحب مطعم أبيض يوفرلك نظام إدارة و مراقبة و متابعة للمطعم وإلا العمل متاعك , من خلال بيئة إفتراضية تخليك ديما متصل بالزبائن متاعك لمعرفة ردود أفعالهم علي الخدمات اللي تقدمها ',
        hotel: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        transporteur: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        vg_agence: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        courtier: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        impremerie: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        avocat: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        comptable: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        menuisier: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        forgeron: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        cristal: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        marbre: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        electricien: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        peinture: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        contracteur: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        architecture: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        parking: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        qiosque: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        location: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        photographe: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        salon_marriage: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        fourniture_marriage: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        coiffure: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        gym: '   نظام إدارة  قاعات الرياضة هو نظام إدارة و  محاسبة و تواصل مع مشتركيك و مراقبة أداءهم و تطور نشاطهم الرياضي من خلال خلق فضاء للتفاعل إفتراضيا مع القاعة لتقييم خدماتها ...',
        pyscine: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        stade: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        cinema: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        theatre: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        farm: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        socite: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل'
                
      ,
      },
      subscribeTo: 'Jetzt abonnieren',
      goToSystem: 'Zum System gehen',
      prosConsText: 'Hat mehrere Vorteile',
      prosConsData: {
        connected: {
          smallText: 'Verbunden',
          largeText: 'Das System hält Sie ständig mit Ihren Kunden über ein umfassendes und interaktives Verzeichnis verbunden, das es ihnen ermöglicht, auf intelligente Weise mit Ihnen zu kommunizieren. Es hält Sie auch über ihre eigenen Steuerungspanels mit Ihrem gesamten Team verbunden, um ihre Arbeit zu verfolgen und ihre Aufgaben zu überwachen.',
        },
        easy: {
          smallText: 'Einfach',
          largeText: 'Das System zielt darauf ab, einfach, aber gleichzeitig effektiv zu sein, durch seine Organisation und Tabulierung in einfacher Weise in seiner aktuellen Version.',
        },
        helpful: {
          smallText: 'Hilfreich',
          largeText: 'Das System bietet Ihnen nicht nur Managementtools, sondern zielt auch darauf ab, Ihnen bei Ihrer Arbeit durch zusätzliche Tools zu helfen, die Ihnen helfen, Ihr Wissen über Ihre Arbeit zu verbessern, wie z.B. ein Medikamentenwörterbuch, eine Enzyklopädie der Krankheiten, ein dreidimensionales Modell des menschlichen Körpers und mehr...',
        },
        progressed: {
          smallText: 'Fortgeschritten',
          largeText: 'Das White System ist ein Cloud-basiertes System, kein auf einem persönlichen Computer installiertes System, daher arbeitet das kreative White-Team an seiner Entwicklung und macht es passend, bequem und benutzerfreundlich, um sich von traditionellen "starren" Systemen zu unterscheiden.',
        },
        supported: {
          smallText: 'Unterstützt',
          largeText: 'Das System wird mit einem umfassenden Benutzerhandbuch und genauen Antworten auf alle Fragen geliefert, die Ihnen in den Sinn kommen könnten, wenn Sie das System verwenden. Sie müssen keinen Experten kontaktieren, um Ihnen beizubringen, wie Sie das System verwenden, da für alle Funktionen eine ausführliche Erklärung vorliegt.',
        },
        smart: {
          smallText: 'Intelligent',
          largeText: 'Das System bereitet einen Algorithmus zur Automatisierung von routinemäßigen und wiederholten Operationen vor, wodurch dem System die Eigenschaft der Intelligenz verliehen wird und routinemäßige Entscheidungen basierend auf dem Benutzerverhalten getroffen werden.',
        },
      },
      subscribeNow: 'Jetzt abonnieren und zwei kostenlose Monate zum Ausprobieren des Systems erhalten',
      openVersion: 'Offene Version',
      mediumVersion: 'Für mittelständische Unternehmen',
      bigVersion: 'Für große Unternehmen',
      currencyText: 'USD',
      fullYearText: 'Vollständiges Jahr',
      prosItemList: {
        1: '2 kostenlose Monate zum Ausprobieren des Systems',
        2: 'Unbegrenzte Anfragen',
        3: 'Kostenloses Erscheinen im Verzeichnis',
        4: 'Einzigartige Kennung (PID)',
        5: 'Verschiedene kostenlose digitale Tools',
        6: 'Möglichkeit zur Verbindung mit intelligenten White-Geräten',
        7: 'Möglichkeit zur Verbindung mit Bankkarte',
        8: 'Intelligente und automatisierte Datenanalyse',
        9: 'Automatisches Datenbackup',
      },
      subscribeBtn: 'Jetzt abonnieren'
    },
    bottomCard:{
      footerVision:'Was ist die Vision der Abyad-Plattform?',
      footerHow:'Wie benutze ich die Plattform?',
      footerWho:'Wer sind wir?',
    }
  },
}
export default GermanyTrans