const GConf = {
    ApiLink : 'https://api.abyedh.com/apiAbyedh/Registration', //https://api.abyedh.com/apiAbyedh
    TostErrorGonf : {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        icon: "🚫"
    },
    TostSuucessGonf : {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    },
    TostInternetGonf : {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    },
    tabContainer: [
        {
            id:1,
            title:' أنظمة نقاط البيع ',
            tagNameItem:'ptvente',
            adsUrl:'ptvente.svg',
            genre:'IL',
            Links:[
                {id:1, tag:'depot', name:'نظام إدارة نقاط بيع الجملة', link:'', img_url: 'storage.gif', color:'#6f858f', col:6, state:'', ready:true},
                {id:2, tag:'magazin', name:'نظام إدارة المغازات', link:'', img_url: 'pt-vente.gif', color:'#5e7968', col:6, state:'', ready:true},
                {id:3, tag:'fruiterie', name:'نظام إدارة نقاط بيع الخضر', link:'', img_url: 'ptvente_fruit.gif', color:'#488720', col:4, state:'', next:true},
                {id:4, tag:'boucherie', name:'نظام إدارة ن. بيع اللحوم ', link:'', img_url: 'ptvente_viande.gif', color:'#bd1304', col:4, state:'', next:true},
                {id:5, tag:'boulengerie', name:'نظام إدارة المخابز ', link:'', img_url: 'ptvente_boulengerie.gif', color:'#bf990d', col:4, state:'', next:true},
                {id:6, tag:'patesserie', name:'نظام إدارة ن. بيع المرطبات  ', link:'', img_url: 'ptvente_patesserie.gif', color:'#a33464', col:4, state:'', next:true},
                {id:7, tag:'epicerie', name:'نظام إدارة البقالات ', link:'', img_url: 'ptvente_small_shop.gif', color:'#6b462e', col:4, state:'', next:true},
                {id:8, tag:'pharmacie', name:'نظام إدارة الصيدليات ', link:'', img_url: 'pharmacie.gif', color:'#6edb8d', col:4, state:'', ready:true},
                {id:9, tag:'librairie', name:'نظام إدارة الكتبيات ', link:'', img_url: 'librairie.gif', color:'#4c4a48', col:4, state:'', next:true},
                {id:10, tag:'boutique', name:'نظام إدارة متاجر الملابس ', link:'', img_url: 'boutique.gif', color:'#b146c2', col:4, state:'', ready:true},
                {id:11, tag:'quincaillerie', name:'نظام إدارة quincaillerie', link:'', img_url: 'handmade.gif', color:'#406163', col:4, state:'', ready:true},
                {id:12, tag:'electromenager', name:'نظام إدارة نقطة بيع الكهروإكترونيات ', link:'', img_url: 'house_electro.gif', color:'#03a3a1', col:6, state:'', ready:true},
                {id:13, tag:'meublerie', name:'نظام إدارة نقطة بيع الأثاث  ', link:'', img_url: 'house.gif', color:'#0078d7', col:6, state:'', ready:true},
            ]
        },
        {
            id:2,
            title:'الأنظمة الصحية',
            tagNameItem:'health',
            adsUrl:'health.svg',
            genre:'LI',
            Links:[
                {id:1, tag:'docteur', name:'نظام إدارة العيادات الطبية', link:'', img_url: 'docteur.gif', color:'#085947', col:6, state:'', ready:true},
                {id:2, tag:'infirmier', name:'نظام إدارة عيادة ممرض  ', link:'', img_url: 'infirmier.gif', color:'#093587', col:6, state:'', next:true},
                {id:2, tag:'clinique', name:'نظام إدارة المصحات  ', link:'', img_url: 'clinique.gif', color:'#04c6ce', col:4, state:'', next:true},
                {id:3, tag:'labo', name:'نظام إدارة المخابر ', link:'', img_url: 'labo.gif', color:'#1dd3b0', col:4, state:'', next:true},
                {id:4, tag:'centre', name:'نظام إدارة المراكز الطبية ', link:'', img_url: 'centre.gif', color:'#52a3c9', col:4, state:''},
            ]
        },
        {
            id:3,
            title:'الأنظمة التعليمية',
            tagNameItem:'education',
            adsUrl:'education.svg',
            genre:'IL',
            Links:[
                {id:1, tag:'garderie', name:'نظام إدارة رياض الأطفال ', link:'', img_url: 'garderie.gif', color:'#cf208f', col:6, state:'', ready:true},
                {id:2, tag:'formation', name:'نظام إدارة  مراكز التكوين   ', link:'', img_url: 'formation.gif', color:'#919034', col:6, state:'', next:true},
                {id:3, tag:'ecole', name:'نظام إدارة المدارس      ', link:'', img_url: 'ecole.gif', color:'#218281', col:4, state:'', next:true},
                {id:4, tag:'lycee', name:'نظام إدارة المعاهد    ', link:'', img_url: 'lycee.gif', color:'#913461', col:4, state:'',ready:true },
                {id:5, tag:'universite', name:'نظام إدارة الجامعات   ', link:'', img_url: 'universite.gif', color:'#553491', col:4, state:'', next:true},
            ]
        },
        {
            id:4,
            title:'أنظمة خدمات الضيافة',
            tagNameItem:'restaurant',
            adsUrl:'restaurant.svg',
            genre:'LI',
            Links:[
                {id:1, tag:'restaurant', name:'نظام إدارة المطاعم ', link:'', img_url: 'restaurant.gif', color:'#35ac67', col:6, state:'', ready:true},
                {id:2, tag:'cafe', name:'نظام إدارة المقاهي   ', link:'', img_url: 'cafe.gif', color:'#363636', col:6, state:'',  ready:true},
                {id:3, tag:'hotels', name:'نظام إدارة الفنادق و النزل  ', link:'', img_url: 'hotel.gif', color:'#247cc7', col:6, state:'', ready:true},
                {id:2, tag:'vg_agence', name:' نظام إدارة وكالات الأسفار   ', link:'', img_url: 'vg-agence.gif', color:'#ce8c64', col:6, state:'', next:true},
            ]
        },
        {
            id:5,
            title:'أنظمة خدمات النقل و التنقل',
            tagNameItem:'transport',
            adsUrl:'transport.svg',
            genre:'Il',
            Links:[
                {id:1, tag:'transporteur', name:' نظام إدارة شركات نقل البضائع  ', link:'', img_url: 'transporteur.gif', color:'#be6e70', col:6, state:'', ready:true},
                {id:3, tag:'autoecole', name:'نظام إدارة مدارس تعليم السياقة   ', link:'', img_url: 'autoecole.gif', color:'#1caaa8', col:6, state:'', ready:true},
                {id:1, tag:'parking', name:'نظام إدارة Parking ', link:'', img_url: 'car_parking.gif', color:'#6a4a37', col:4, state:'', next:true},
                {id:2, tag:'qiosque', name:'نظام إدارة Qiosque   ', link:'', img_url: 'car_qiosque.gif', color:'#2d4664', col:4, state:''},
                {id:3, tag:'location', name:'نظام كراء السيارات  ', link:'', img_url: 'car.gif', color:'#853d07', col:4, state:'', next:true},
                // {id:3, tag:'ptvGros', name:'نظام إدارة سيارات التاكسي  ', link:'', img_url: 'taxi.gif', color:'#5e7968', col:6, state:''},
                // {id:4, tag:'ptvGros', name:' نظام إدارة سيارات الأجرة   ', link:'', img_url: 'louage.gif', color:'#5e7968', col:6, state:''},
            ]
        },
      //   {
      //       id:6,
      //       title:'أنظمة  أخري',
      //       tagNameItem:'',
      //        adsUrl:'service-g.svg',
      //       genre:'LI',
      //       Links:[
      //          //  {id:1, tag:'samsar', name:' نظام وسيط عقاري ', link:'', img_url: 'samsar.gif', color:'#455b7a', col:6, state:''},
      //          //  {id:2, tag:'impremerie', name:' نظام إدارة خدمات الطباعة   ', link:'', img_url: 'impremerie.gif', color:'#4c4a48', col:6, state:''},
      //           {id:3, tag:'avocat', name:' نظام إدارة مكتب محامي  ', link:'', img_url: 'avocat.gif', color:'#7a8890', col:6, state:'', ready:true},
      //           {id:4, tag:'comptable', name:'نظام إدارة مكتب محاسب   ', link:'', img_url: 'comptable.gif', color:'#a77a51', col:6, state:'', next:true},
      //       ]
      //   },
        {
            id:7,
            title:'أنظمة خدمات حرفية',
            tagNameItem:'handmade',
            adsUrl:'handmade.svg',
            genre:'LI',
            Links:[
                {id:1, tag:'menuisier', name:'نظام إدارة ورشات النجارة ', link:'', img_url: 'handmade_menuisier.gif', color:'#cdb556', col:6, state:''},
                {id:2, tag:'forgeron', name:'نظام إدارة ورشات الحدادة   ', link:'', img_url: 'handmade_forgeron.gif', color:'#545452', col:6, state:''},
                {id:3, tag:'marbrerie', name:'نظام إدارة ورشات تركيب البلور  ', link:'', img_url: 'handmade_cristal.gif', color:'#f0c400', col:6, state:''},
                {id:4, tag:'marbrerie', name:'نظام إدارة ورشات الرخام  ', link:'', img_url: 'handmade_marbre.gif', color:'#0ec2cb', col:6, state:''},
                {id:6, tag:'electricien', name:' نظام إدارة شركات الكهرباء  ', link:'', img_url: 'handmade_electricien.gif', color:'#5e091c', col:6, state:''},
                {id:7, tag:'peinture', name:'نظام إدارة شركات الدهان  ', link:'', img_url: 'handmade_peinture.gif', color:'#28568f', col:6, state:''},
            ]
        },
        {
            id:8,
            title:'أنظمة خدمات عقارية',
            tagNameItem:'construction',
            adsUrl:'construction.svg',
            genre:'IL',
            Links:[
                {id:1, tag:'contracteur', name:' نظام إدارة شركات المقاولة ', link:'', img_url: 'chantier_contracteur.gif', color:'#808080', col:6, state:''},
                {id:1, tag:'courtier', name:' نظام وسيط عقاري ', link:'', img_url: 'samsar.gif', color:'#455b7a', col:6, state:''},
                {id:3, tag:'architecture', name:' نظام إدارة مكتب مهندس معماري  ', link:'', img_url: 'chantier_architecture.gif', color:'#1b9476', col:12, state:''},
            ]
        },
        {
            id:10,
            title:'أنظمة خدمات الزواج',
            tagNameItem:'wedding',
            adsUrl:'wedding.svg',
            genre:'LI',
            Links:[
               {id:4, tag:'coiffure', name:'نظام إدارة قاعات الحلاقة  ', link:'', img_url: 'coiffure.gif', color:'#0b97bf', col:6, state:'', next:true},
                {id:2, tag:'salle_marriage', name:'نظام إدارة قاعات الافراح   ', link:'', img_url: 'wedding_salon_marriage.gif', color:'#c30052', col:6, state:'', ready:true},
                {id:3, tag:'fourniture_marriage', name:'نظام بيع لوازم الأفراح  ', link:'', img_url: 'wedding_fourniture_marriage.gif', color:'#29615f', col:6, state:''},
                {id:1, tag:'photographe', name:' نظام إدارة استديو تصوير', link:'', img_url: 'wedding_photographe.gif', color:'#424d40', col:6, state:'' }, 
            ]
        },
        {
            id:11,
            title:'أنظمة رياضية',
            tagNameItem:'sport',
            adsUrl:'sport.svg',
            genre:'IL',
            Links:[
                {id:1, tag:'gym', name:'نظام إدارة قاعات الرياضة  ', link:'', img_url: 'gym.gif', color:'#7aadab', col:6, state:'', ready:true},
                {id:2, tag:'pyscine', name:'نظام إدارة المسابح  ', link:'', img_url: 'pyscine.gif', color:'#009fe3', col:6, state:'', next:true},
                {id:3, tag:'stade', name:'نظام إدارة الملاعب الرياضية ', link:'', img_url: 'stade.gif', color:'#587e6d', col:12, state:'', next:true},
                {id:4, tag:'cinema', name:' نظام إدارة قاعة سنما  ', link:'', img_url: 'art_cinema.gif', color:'#2c7177', col:6, state:''},
                {id:5, tag:'theatre', name:'نظام إدارة قاعة مسرح  ', link:'', img_url: 'art_theatre.gif', color:'#402e6b', col:6, state:''},
            ]
        },
        {
            id:12,
            title:'أنظمة أخري',
            tagNameItem:'other',
            adsUrl:'other.svg',
            genre:'LI',
            Links:[
               {id:3, tag:'avocat', name:' نظام إدارة مكتب محامي  ', link:'', img_url: 'avocat.gif', color:'#7a8890', col:6, state:'', ready:true},
                {id:4, tag:'comptable', name:'نظام إدارة مكتب محاسب   ', link:'', img_url: 'comptable.gif', color:'#a77a51', col:6, state:'', next:true},
                {id:1, tag:'farmer', name:'نظام إدارة الضيعات الفلاحية ', link:'', img_url: 'champ.gif', color:'#72bcc7', col:6, state:'', next:true},
                {id:2, tag:'socite', name:'نظام إدارة شركة قابضة  ', link:'', img_url: 'socite.gif', color:'#32a6c9', col:6, state:''},

            ]
        },
    ],
    TostAddedToTimming : {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: 'w-alert-10',
      theme: "colored",
      
  },
    //leafLeft
    LeafleftIcon : {
      iconUrl: require("leaflet/dist/images/position.gif"),
      iconRetinaUrl: require("leaflet/dist/images/position.gif"),
      iconSize: [10,10],
      shadowSize: [0,0],
      shadowUrl: '',
      shadowSize:   [0,0],
      iconAnchor:   [0,0],
      shadowAnchor: [0,0],
      popupAnchor:  [0,0]
    },
    LeafleftIconP : {
         iconUrl: require("leaflet/dist/images/position-personal.gif"),
         iconRetinaUrl: require("leaflet/dist/images/position-personal.gif"),
         iconSize: [10,10],
         shadowSize: [0,0],
         shadowUrl: '',
         shadowSize:   [0,0],
         iconAnchor:   [0,0],
         shadowAnchor: [0,0],
         popupAnchor:  [0,0]
    },
    landing:{
        depot:{
            colorTheme:'#6f858f',
            adsImageUrl:'storage.svg',
            systemTitle:'نظام إداراة نقاط البيع بالجملة ',
            businesName:'الشركة ',
            systemUrl:'https://depot.system.abyedh.com',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم .  كما يقدم لك واجهة رسومية (caisse) لتسجيل مبيعات الشاحنات و طباعة الفواتير.  بالإضافة إلي مجموعة من الأدوات  (مثل واجهة  تسجيل الطلبات    commandes  )  التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' , tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        magazin:{
            colorTheme:'#5e7968',
            adsImageUrl:'ptvente_shop.svg',
            systemTitle:'نظام إداراة  المغازات ',
            businesName:'المغازة ',
            systemUrl:'https://shop.system.abyedh.com',
            adsText:'نظام إداراة المغازاة و نقاط البيع يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            imageCarousel:[
               {id:1, title:'إدارة العامة', color:'#4287f5', icon:'link-45deg', link:'magazin/magazin1.PNG'},
               {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', link:'magazin/magazin2.PNG'},
               {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', link:'magazin/magazin3.PNG'},
               {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', link:'magazin/magazin4.PNG'},
               {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', link:'magazin/magazin5.PNG'},
       
           ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        fruiterie:{
            colorTheme:'#488720',
            adsImageUrl:'ptvente_shop.svg',
            systemTitle:'نظام إداراة نقاط  بيع الخضر ',
            systemUrl:'https://commerce.system.abyedh.com/',
            adsText:'نظام إداراة نقاط  بيع الخضر  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        boucherie:{
            colorTheme:'#bd1304',
            adsImageUrl:'ptvente_shop.svg',
            systemTitle:'نظام إدارة المخابز  ',
            systemUrl:'https://commerce.system.abyedh.com/',
            adsText:'نظام إداراة المخابز  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        boulengerie:{
            colorTheme:'#bf990d',
            adsImageUrl:'ptvente_shop.svg',
            systemTitle:'نظام إداراة نقاط  بيع اللحوم ',
            systemUrl:'https://commerce.system.abyedh.com/',
            adsText:'نظام إداراة نقاط بيع اللحوم  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        patesserie:{
            colorTheme:'#a33464',
            adsImageUrl:'ptvente_shop.svg',
            systemTitle:'نظام إداراة نقاط  بيع الحلويات ',
            systemUrl:'https://commerce.system.abyedh.com/',
            adsText:'نظام إداراة نقاط بيع الحلويات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        epicerie:{
            colorTheme:'#6b462e',
            adsImageUrl:'ptvente_shop.svg',
            systemTitle:'نظام إدارة البقالات ',
            systemUrl:'https://commerce.system.abyedh.com/',
            adsText:'نظام إداراة البقالات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        pharmacie:{
            colorTheme:'#6edb8d',
            adsImageUrl:'pharmacie.svg',
            systemTitle:'نظام إداراة الصيدليات ',
            businesName:'الصيدلية ',
            systemUrl:'https://pharmacy.system.abyedh.com',
            adsText:'نظام إداراة نقاط الصيدليات يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        librairie:{
            colorTheme:'#4c4a48',
            adsImageUrl:'librairie.svg',
            systemTitle:'نظام إداراة الكتبيات ',
            systemUrl:'https://commerce.system.abyedh.com',
            adsText:'نظام إداراة الكتبيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        boutique:{
            colorTheme:'#b146c2',
            adsImageUrl:'boutique.svg',
            systemTitle:'نظام إداراة نقاط  بيع الملابس ',
            businesName:'المتجر ',
            systemUrl:'https://boutique.system.abyedh.com',
            adsText:'نظام إداراة نقاط بيع الملابس  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            imageCarousel:[
               {id:1, title:'إدارة العامة', color:'#4287f5', icon:'link-45deg', link:'boutique/boutique1.PNG'},
               {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', link:'boutique/boutique2.PNG'},
               {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', link:'boutique/boutique3.PNG'},
               {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', link:'boutique/boutique4.PNG'},
               {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', link:'boutique/boutique5.PNG'},
       
           ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        quincaillerie:{
            colorTheme:'#406163',
            adsImageUrl:'quincaillerie.svg',
            systemTitle:' Quancaillerie نظام إدارة  ',
            businesName:'المتجر ',
            systemUrl:'https://hardware.system.abyedh.com',
            adsText:'نظام إداراة Quancaillerie  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        electromenager:{
            colorTheme:'#0078d7',
            adsImageUrl:'house_electro.svg',
            systemTitle:'نظام إداراة نقاط  بيع الالكترونيات ',
            systemUrl:'https://appliances.system.abyedh.com',
            adsText:'نظام إداراة نقاط بيع الالكترونيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        meublerie:{
            colorTheme:'#0078d7',
            adsImageUrl:'house_meuble.svg',
            systemTitle:'نظام إداراة نقاط  بيع الاثاث ',
            systemUrl:'https://furniture.system.abyedh.com',
            adsText:'نظام إداراة نقاط ابيع الاثاث  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        docteur:{
            colorTheme:'#085947',
            adsImageUrl:'docteur.svg',
            systemTitle:' نظام إدارة العيادات الطبية ',
            businesName:'العيادة ',
            systemUrl:'https://doctor.system.abyedh.com',
            adsText:' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما متصلا مع مرضاك  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            imageCarousel:[
               {id:1, title:'إدارة العامة', color:'#4287f5', icon:'link-45deg', link:'docteur/docteur1.PNG'},
               {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', link:'docteur/docteur2.PNG'},
               {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', link:'docteur/docteur3.PNG'},
               {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', link:'docteur/docteur4.PNG'},
               {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', link:'docteur/docteur5.PNG'},
       
           ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        infirmier:{
         colorTheme:'#093587',
         adsImageUrl:'infirmier.svg',
         systemTitle:' نظام إدارة محلات التمريض ',
         businesName:'العيادة ',
         systemUrl:'https://infirmier.system.abyedh.com/',
         adsText:' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما بإتصال بالمرضي  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
         prosList:[
             {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
             {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
             {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
             {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
             {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
             {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
     
         ],
         imageCarousel:[
            {id:1, title:'إدارة العامة', color:'#4287f5', icon:'link-45deg', link:'docteur/docteur1.PNG'},
            {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', link:'docteur/docteur2.PNG'},
            {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', link:'docteur/docteur3.PNG'},
            {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', link:'docteur/docteur4.PNG'},
            {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', link:'docteur/docteur5.PNG'},
    
        ],
         prices:[
              {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
              pos:[
                 {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                 {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                 {id:3, text:' ضهور مجاني في الدليل', checked:true},
                 {id:4, text:' معرف وحيد (PID)', checked:true},
                 {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                 {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                 {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                 {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                 {id:9, text:' حفض آلي للبيانات', checked:false},
              ]},
              {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
              pos:[
                 {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                 {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                 {id:3, text:' ضهور مجاني في الدليل', checked:true},
                 {id:4, text:' معرف وحيد (PID)', checked:true},
                 {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                 {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                 {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                 {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                 {id:9, text:' حفض آلي للبيانات', checked:false},
              ]},
              {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
              pos:[
                 {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                 {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                 {id:3, text:' ضهور مجاني في الدليل', checked:true},
                 {id:4, text:' معرف وحيد (PID)', checked:true},
                 {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                 {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                 {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                 {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                 {id:9, text:' حفض آلي للبيانات', checked:true},
              ]},
         ]
        },
        clinique:{
            colorTheme:'#04c6ce',
            adsImageUrl:'clinique.svg',
            systemTitle:' نظام إدارة المصحات ',
            systemUrl:'https://clinique.system.abyedh.com/',
            adsText:'نظام إدارة المصحات يساعدك علي تتبع جميع العمليات و الأنشطة التي تحدث داخل مصحتك . كما يساعدك علي متابعة مواعيد العمليات و تقارير يومية لفهم تطور الحالة الصحية للمرضي, بالإضافة لجملة من الأدوات الرقمية مثل واجهة تسجيل حضور فريق العمل و واجهة متابعة مريض معين  ',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        labo:{
            colorTheme:'#1dd3b0',
            adsImageUrl:'labo.svg',
            systemTitle:' نظام إدارة المخابر ',
            systemUrl:'https://laboratoire.system.abyedh.com/',
            adsText:'يساعدك نظام إدارة المخابر الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        centre:{
            colorTheme:'#52a3c9',
            adsImageUrl:'centre.svg',
            systemTitle:'  نظام إدارة المراكز الطبية ',
            systemUrl:'#',
            adsText:'يساعدك نظام إدارة المراكز الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        garderie:{
            colorTheme:'#cf208f',
            adsImageUrl:'garderie.svg',
            systemTitle:'نظام  إدارة رياض الأطفال',
            businesName:'الروضة ',
            systemUrl:'https://daycare.system.abyedh.com',
            adsText:'نظام إدارة  رياض الأطفال يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            imageCarousel:[
               {id:1, title:'إدارة العامة', color:'#4287f5', icon:'link-45deg', link:'garderie/garderie1.PNG'},
               {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', link:'garderie/garderie2.PNG'},
               {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', link:'garderie/garderie3.PNG'},
               {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', link:'garderie/garderie4.PNG'},
               {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', link:'garderie/garderie5.PNG'},
       
           ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        autoecole:{
            colorTheme:'#1caaa8',
            adsImageUrl:'autoecole.svg',
            systemTitle:'نظام مدرسة تعليم سياقة ',
            businesName:'المدرسة ',
            systemUrl:'https://driving-school.system.abyedh.com',
            adsText:'يساعدك نظام إدارة    مدارس تعليم السياقة علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        ecole:{
            colorTheme:'#4a9998',
            adsImageUrl:'ecole.svg',
            systemTitle:'نظام إداراة المدارس الخاصة ',
            businesName:'المدرسة ',
            systemUrl:'https://ecole.system.abyedh.com/',
            adsText:'نظام إدارة المدارس الخاصة يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        formation:{
         colorTheme:'#4a9998',
         adsImageUrl:'formation.svg',
         systemTitle:'نظام إداراة  مراكز التكوين الخاص ',
         businesName:'المركز ',
         systemUrl:'https://formation.system.abyedh.com/',
         adsText:'نظام إدارة  مراكز التكوين   يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
         prosList:[
             {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
             {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
             {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
             {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
             {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
             {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
     
         ],
         prices:[
              {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
              pos:[
                 {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                 {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                 {id:3, text:' ضهور مجاني في الدليل', checked:true},
                 {id:4, text:' معرف وحيد (PID)', checked:true},
                 {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                 {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                 {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                 {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                 {id:9, text:' حفض آلي للبيانات', checked:false},
              ]},
              {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
              pos:[
                 {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                 {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                 {id:3, text:' ضهور مجاني في الدليل', checked:true},
                 {id:4, text:' معرف وحيد (PID)', checked:true},
                 {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                 {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                 {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                 {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                 {id:9, text:' حفض آلي للبيانات', checked:false},
              ]},
              {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
              pos:[
                 {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                 {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                 {id:3, text:' ضهور مجاني في الدليل', checked:true},
                 {id:4, text:' معرف وحيد (PID)', checked:true},
                 {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                 {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                 {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                 {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                 {id:9, text:' حفض آلي للبيانات', checked:true},
              ]},
         ]
        },
        lycee:{
            colorTheme:'#4a9998',
            adsImageUrl:'lycee.svg',
            systemTitle:'نظام إدارة المعاهد الخاصة',
            systemUrl:'https://lycee.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        universite:{
            colorTheme:'#4a9998',
            adsImageUrl:'universite.svg',
            systemTitle:'نظام إداراة الجامعات الخاصة ',
            systemUrl:'https://universite.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        restaurant:{
            colorTheme:'#35ac67',
            adsImageUrl:'restaurant.svg',
            systemTitle:'نظام إداراة المطاعم ',
            businesName:'المطعم',
            systemUrl:'https://restaurant.system.abyedh.com',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            imageCarousel:[
               {id:1, title:'إدارة العامة', color:'#4287f5', icon:'link-45deg', link:'restaurant/main.PNG'},
               {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', link:'restaurant/menu.PNG'},
               {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', link:'restaurant/facture.PNG'},
               {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', link:'restaurant/stock.PNG'},
               {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', link:'restaurant/tables.PNG'},
               {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', link:'restaurant/caisse.PNG'},
       
           ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        cafe:{
            colorTheme:'#363636',
            adsImageUrl:'cafe.svg',
            systemTitle:'نظام إداراة المقاهي و قاعات الشاي',
            systemUrl:'https://cafe.system.abyedh.com',
            businesName:'المقهي ',
            adsText:'نكانك صاحب مطعم أبيض يوفرلك نظام إدارة و مراقبة و متابعة للمطعم وإلا العمل متاعك , من خلال بيئة إفتراضية تخليك ديما متصل بالزبائن متاعك لمعرفة ردود أفعالهم علي الخدمات اللي تقدمها ' ,
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            imageCarousel:[
               {id:1, title:'إدارة العامة', color:'#4287f5', icon:'link-45deg', link:'cafe/cafe1.PNG'},
               {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', link:'cafe/cafe2.PNG'},
               {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', link:'cafe/cafe3.PNG'},
               {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', link:'cafe/cafe4.PNG'},
               {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', link:'cafe/cafe5.PNG'},
       
           ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        hotel:{
            colorTheme:'#247cc7',
            adsImageUrl:'hotel.svg',
            systemTitle:'نظام إداراة الفنادق و النزل',
            systemUrl:'https://hotel.system.abyedh.com',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        transporteur:{
            colorTheme:'#be6e70',
            adsImageUrl:'transporteur.svg',
            systemTitle:'نظام إداراة شركات نقل البظائع',
            systemUrl:'https://transporter.system.abyedh.com',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        vg_agence:{
            colorTheme:'#ce8c64',
            adsImageUrl:'vg-agence.svg',
            systemTitle:'نظام إداراة وكالات الأسفار',
            systemUrl:'https://voyage.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        courtier:{
            colorTheme:'#455b7a',
            adsImageUrl:'samsar.svg',
            systemTitle:'نظام إداراة مكتب وسيط عقاري',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        impremerie:{
            colorTheme:'#4c4a48',
            adsImageUrl:'impremerie.svg',
            systemTitle:'نظام إداراة المطابع',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        avocat:{
            colorTheme:'#7a8890',
            adsImageUrl:'avocat.svg',
            systemTitle:'نظام إداراة مكتب محامي',
            businesName:'المكتب',
            systemUrl:'https://lawyer.system.abyedh.com',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        comptable:{
            colorTheme:'#a77a51',
            adsImageUrl:'comptable.svg',
            systemTitle:'نظام إداراة مكتب محاسب',
            systemUrl:'https://comptable.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        menuisier:{
            colorTheme:'#cdb556',
            adsImageUrl:'menuisier.svg',
            systemTitle:'نظام إداراة ورشة نجارة',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        forgeron:{
            colorTheme:'#cdb556',
            adsImageUrl:'forgeron.svg',
            systemTitle:'نظام إداراة ورشة حدادة',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        cristalerie:{
            colorTheme:'#cdb556',
            adsImageUrl:'cristal.svg',
            systemTitle:'نظام إداراة ورشة تركيب بلور',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        marbrerie:{
            colorTheme:'#cdb556',
            adsImageUrl:'marbre.svg',
            systemTitle:'نظام إداراة ورشات الرخام',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        electricien:{
            colorTheme:'#cdb556',
            adsImageUrl:'electricien.svg',
            systemTitle:'نظام إداراة شركات الكهرباء',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        peinture:{
            colorTheme:'#cdb556',
            adsImageUrl:'peinture.svg',
            systemTitle:'نظام إداراة شركات  الدهان',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        contracteur:{
            colorTheme:'#406163',
            adsImageUrl:'contracteur.svg',
            systemTitle:'نظام إداراة شركات المقاولة',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        architecture:{
            colorTheme:'#406163',
            adsImageUrl:'architecture.svg',
            systemTitle:'نظام إداراة مكتب مهندس معماري',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        parking:{
            colorTheme:'#2d4664',
            adsImageUrl:'parking.svg',
            systemTitle:'Parking نظام إداراة ',
            systemUrl:'https://parking.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        qiosque:{
            colorTheme:'#2d4664',
            adsImageUrl:'qiosque.svg',
            systemTitle:' Qiosque نظام إداراة',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        location:{
            colorTheme:'#2d4664',
            adsImageUrl:'location.svg',
            systemTitle:'نظام إداراة شركات كراء السيارات',
            systemUrl:'https://location.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        photographe:{
            colorTheme:'#c30052',
            adsImageUrl:'photographe.svg',
            systemTitle:'نظام إداراة إستديو تصوير',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        salle_marriage:{
            colorTheme:'#c30052',
            adsImageUrl:'salon_marriage.svg',
            systemTitle:'نظام إداراة قاعة أفراح و مؤتمرات',
            systemUrl:'https://banquet-hall.system.abyedh.com',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        fourniture_marriage:{
            colorTheme:'#c30052',
            adsImageUrl:'fourniture_marriage.svg',
            systemTitle:'نظام إداراة نقاط  بيع لوازم الأفراح ',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        coiffure:{
            colorTheme:'#0b97bf',
            adsImageUrl:'coiffure.svg',
            systemTitle:'نظام إداراة قاعات الحلاقة',
            businesName:'قاعة الحلاقة',
            systemUrl:'https://coiffure.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        gym:{
            colorTheme:'#7aadab',
            adsImageUrl:'gym.svg',
            systemTitle:'نظام إداراة قاعات الرياضة',
            businesName:'قاعة الرياضة',
            systemUrl:'https://gym.system.abyedh.com',
            adsText:'   نظام إدارة  قاعات الرياضة هو نظام إدارة و  محاسبة و تواصل مع مشتركيك و مراقبة أداءهم و تطور نشاطهم الرياضي من خلال خلق فضاء للتفاعل إفتراضيا مع القاعة لتقييم خدماتها ...',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            imageCarousel:[
               {id:1, title:'إدارة العامة', color:'#4287f5', icon:'link-45deg', link:'gym/gym1.PNG'},
               {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', link:'gym/gym2.PNG'},
               {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', link:'gym/gym3.PNG'},
               {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', link:'gym/gym4.PNG'},
               {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', link:'gym/gym5.PNG'},
       
           ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        pyscine:{
            colorTheme:'#009fe3',
            adsImageUrl:'pyscine.svg',
            systemTitle:'نظام إداراة المسابح',
            systemUrl:'https://pyscine.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        stade:{
            colorTheme:'#587e6d',
            adsImageUrl:'stade.svg',
            systemTitle:'نظام إداراة الملاعب الرياضية',
            systemUrl:'https://stade.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        cinema:{
            colorTheme:'#2c7177',
            adsImageUrl:'cinema.svg',
            systemTitle:'نظام إداراة قاعات السنما',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        theatre:{
            colorTheme:'#2c7177',
            adsImageUrl:'theatre.svg',
            systemTitle:'نظام إداراة قاعات المسرح',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        farmer:{
            colorTheme:'#72bcc7',
            adsImageUrl:'champ.svg',
            systemTitle:'نظام إداراة الضيعات الفلاحية',
            systemUrl:'https://fermier.system.abyedh.com/',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        },
        socite:{
            colorTheme:'#32a6c9',
            adsImageUrl:'socite.svg',
            systemTitle:'نظام إداراة الشركات القابضة',
            systemUrl:'#',
            adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
            prosList:[
                {id:1, title:'متصل', tag:'connected', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
                {id:2, title:'سهل', tag:'easy', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
                {id:3, title:'معين', tag:'helpfull', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
                {id:4, title:'متطور', tag:'progressed', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
                {id:5, title:' مدعوم', tag:'supported', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
                {id:6, title:'ذكي', tag:'smart', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
        
            ],
            prices:[
                 {id:1, tagName:'openVersion' ,tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'mediumVersion' , tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                    {id:9, text:' حفض آلي للبيانات', checked:false},
                 ]},
                 {id:1, tagName:'bigVersion' , tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
                 pos:[
                    {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                    {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                    {id:3, text:' ضهور مجاني في الدليل', checked:true},
                    {id:4, text:' معرف وحيد (PID)', checked:true},
                    {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                    {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                    {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                    {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                    {id:9, text:' حفض آلي للبيانات', checked:true},
                 ]},
            ]
        }

    },
    TunMap:[
        {id:"1", name:"Tunis", value:"Tunis", arab_name:"تونس", text:"تونس",lan:"36.8065",lng:"10.1815"},
        {id:"2", name:"Sfax",value:"Sfax", arab_name:"صفاقص", text:"صفاقص",lan:"34.7398",lng:"10.7600"},
        {id:"3", name:"Nabeul", value:"Nabeul", arab_name:"نابل", text:"نابل", lan:"36.4513",lng:"10.7357"},
        {id:"4", name:"Sousse", value:"Sousse", arab_name:"سوسة", text:"سوسة",lan:"35.8245",lng:"10.6346"},
        {id:"5", name:"Ben Arous",value:"Ben Arous", arab_name:"بن عروس",text:"بن عروس",lan:"36.7435",lng:"10.2320"},
        {id:"6", name:"Ariana",value:"Ariana", arab_name:"أريانة",text:"أريانة",lan:"36.8665",lng:"10.1647"},
        {id:"7", name:"Kairouan",value:"Kairouan", arab_name:"القيروان",text:"القيروان",lan:"35.6712",lng:"10.1005"},
        {id:"8", name:"Bizerte",value:"Bizerte", arab_name:"بنزرت",text:"بنزرت",lan:"37.2768",lng:"9.8642"},
        {id:"9", name:"Monastir",value:"Monastir", arab_name:"المنستير",text:"المنستير",lan:"35.7643",lng:"10.8113"},
        {id:"10", name:"Medenine",value:"Medenine", arab_name:"مدنين",text:"مدنين",lan:"33.3399",lng:"10.4959"},
        {id:"11", name:"Kasserine",value:"Kasserine", arab_name:"القصرين",text:"القصرين",lan:"35.0809",lng:"8.6601"},
        {id:"12", name:"Sidi Bouzid",value:"Sidi Bouzid", arab_name:"سيدي بوزيد",text:"سيدي بوزيد",lan:"34.9339",lng:"9.4562"},
        {id:"13", name:"Mahdia",value:"Mahdia", arab_name:"المهدية",text:"المهدية",lan:"35.3353",lng:"10.8903"},
        {id:"14", name:"Jendouba",value:"Jendouba", arab_name:"جندوبة",text:"جندوبة",lan:"36.5072",lng:"8.7757"},
        {id:"15", name:"Manouba",value:"Manouba", arab_name:"منوبة",text:"منوبة",lan:"36.8093",lng:"10.0863"},
        {id:"16", name:"Gabes",value:"Gabes", arab_name:"قابس",text:"قابس",lan:"33.8881",lng:"10.0975"},
        {id:"17", name:"Gafsa",value:"Gafsa", arab_name:"قفصة",text:"قفصة",lan:"34.4311",lng:"8.7757"},
        {id:"18", name:"Beja",value:"Beja", arab_name:"باجة",text:"باجة",lan:"36.7333",lng:"9.1844"},
        {id:"19", name:"Kef",value:"Kef", arab_name:"الكاف",text:"الكاف",lan:"36.1231",lng:"8.6601"},
        {id:"20", name:"Siliana",value:"Siliana", arab_name:"سليانة",text:"سليانة",lan:"36.0887",lng:"9.3645"},
        {id:"21", name:"Zaghouan",value:"Zaghouan", arab_name:"زغوان",text:"زغوان",lan:"36.4091",lng:"10.1423"},
        {id:"22", name:"Kebili",value:"Kebili", arab_name:"قبلي",text:"قبلي",lan:"33.1245",lng:"8.8363"},
        {id:"23", name:"Tataouine",value:"Tataouine", arab_name:"تطاوين",text:"تطاوين",lan:"32.9211",lng:"10.4509"},
        {id:"24", name:"Tozeur",value:"Tozeur", arab_name:"توزر",text:"توزر",lan:"33.9185",lng:"8.1229"}
    ],
    TunDeleg2:[
        {id:"1",id2:"1",key:"Tunis",value:"Carthage",text:"قرطاج"},
        {id:"2",id2:"2",key:"Tunis",value:"Al Madinah",text:"المدينة  "},
        {id:"3",id2:"3",key:"Tunis",value:"Bab El Bhar",text:"باب البحر"},
        {id:"4",id2:"4",key:"Tunis",value:"Bab Souika",text:"باب سويقة"},
        {id:"5",id2:"5",key:"Tunis",value:"El Omrane",text:"العمران"},
        {id:"6",id2:"6",key:"Tunis",value:"ffff",text:"العمران الأعلى"},
        {id:"7",id2:"7",key:"Tunis",value:"Ettahrir",text:"التحرير"},
        {id:"8",id2:"8",key:"Tunis",value:"El Menzah",text:"المنزه"},
        {id:"9",id2:"9",key:"Tunis",value:"Cité El Khadra",text:"حي الخضراء"},
        {id:"10",id2:"10",key:"Tunis",value:"Le Bardo",text:"باردو"},
        {id:"11",id2:"11",key:"Tunis",value:"Séjoumi",text:"السيجومي"},
        {id:"12",id2:"12",key:"Tunis",value:"Ezzouhour",text:"الزهور"},
        {id:"13",id2:"13",key:"Tunis",value:"1",text:"الحرائرية"},
        {id:"14",id2:"14",key:"Tunis",value:"Sidi Hassine",text:"سيدي حسين"},
        {id:"15",id2:"15",key:"Tunis",value:"El Ouardia",text:"الوردية"},
        {id:"16",id2:"16",key:"Tunis",value:"El Kabaria",text:"الكبارية"},
        {id:"17",id2:"17",key:"Tunis",value:"Sidi El Béchir",text:"سيدي البشير"},
        {id:"18",id2:"18",key:"Tunis",value:"Djebel Jelloud",text:"جبل الجلود"},
        {id:"19",id2:"19",key:"Tunis",value:"1",text:"حلق الوادي"},
        {id:"20",id2:"20",key:"Tunis",value:"Le Kram",text:"الكرم"},
        {id:"21",id2:"21",key:"Tunis",value:"1",text:"المرسى"},
        {id:"22",id2:"22",key:"Ariana",value:"Ariana Ville",text:"أريانة المدينة"},
        {id:"23",id2:"23",key:"Ariana",value:"La Soukra",text:"سكرة"},
        {id:"24",id2:"24",key:"Ariana",value:"Raoued",text:"رواد"},
        {id:"25",id2:"25",key:"Ariana",value:"Kalâat el-Andalous",text:"قلعة الأندلس"},
        {id:"26",id2:"26",key:"Ariana",value:"Sidi Thabet",text:"سيدي ثابت"},
        {id:"27",id2:"27",key:"Ariana",value:"1",text:"حي التضامن"},
        {id:"28",id2:"28",key:"Ariana",value:"1",text:"المنيهلة"},
        {id:"29",id2:"29",key:"Manouba",value:"La Manouba",text:"منوبة"},
        {id:"30",id2:"30",key:"Manouba",value:"1",text:"وادي الليل"},
        {id:"31",id2:"31",key:"Manouba",value:"Tebourba",text:"طبربة"},
        {id:"32",id2:"32",key:"Manouba",value:"1",text:"البطان"},
        {id:"33",id2:"33",key:"Manouba",value:"Djedeida",text:"الجديدة"},
        {id:"34",id2:"34",key:"Manouba",value:"1",text:"المرناقية"},
        {id:"35",id2:"35",key:"Manouba",value:"Borj El Amri",text:"برج العامري"},
        {id:"36",id2:"36",key:"Manouba",value:"Douar Hicher",text:"دوار هيشر"},
        {id:"37",id2:"37",key:"Ben Arous",value:"Ben Arous",text:"بن عروس"},
        {id:"38",id2:"38",key:"Ben Arous",value:"Medina Jedida",text:"المدينة الجديدة"},
        {id:"39",id2:"39",key:"Ben Arous",value:"El Mourouj",text:"المروج"},
        {id:"40",id2:"40",key:"ben arous",value:"1",text:"حمام الأنف"},
        {id:"41",id2:"41",key:"Ben Arous",value:"Hammam Chott",text:"حمام الشط"},
        {id:"42",id2:"42",key:"Ben Arous",value:"Bou Mhel el-Bassatine",text:"بومهل البساتين"},
        {id:"43",id2:"43",key:"Ben Arous",value:"Ezzahra",text:"الزهراء"},
        {id:"44",id2:"44",key:"Ben Arous",value:"Radès",text:"رادس"},
        {id:"45",id2:"45",key:"Ben Arous",value:"Mégrine",text:"مقرين"},
        {id:"46",id2:"46",key:"Ben Arous",value:"Mohamedia",text:"المحمدية"},
        {id:"47",id2:"47",key:"Ben Arous",value:"Fouchana",text:"فوشانة"},
        {id:"48",id2:"48",key:"Ben Arous",value:"Mornag",text:"مرناق"},
        {id:"49",id2:"49",key:"Nabeul",value:"Nabeul",text:"نابل"},
        {id:"50",id2:"50",key:"nabeul",value:"1",text:" دار شعبان الفهري"},
        {id:"51",id2:"51",key:"nabeul",value:"1",text:"بني خيار"},
        {id:"52",id2:"52",key:"Nabeul",value:"Korba",text:"قربة"},
        {id:"53",id2:"53",key:"Nabeul",value:"Menzel Temime",text:"منزل تميم"},
        {id:"54",id2:"54",key:"Nabeul",value:"El Mida",text:"الميدة"},
        {id:"55",id2:"55",key:"Nabeul",value:"Kélibia",text:"قليبية"},
        {id:"56",id2:"56",key:"Nabeul",value:"Hammam Ghezèze",text:"حمام الأغزاز"},
        {id:"57",id2:"57",key:"Nabeul",value:"El Haouaria",text:"الهوارية"},
        {id:"58",id2:"58",key:"nabeul",value:"1",text:" تاكلسة"},
        {id:"59",id2:"59",key:"nabeul",value:"1",text:" سليمان"},
        {id:"60",id2:"60",key:"nabeul",value:"1",text:" منزل بوزلفة"},
        {id:"61",id2:"61",key:"nabeul",value:"1",text:" بني خلاد"},
        {id:"62",id2:"62",key:"nabeul",value:"1",text:" قرمبالية"},
        {id:"63",id2:"63",key:"nabeul",value:"1",text:" بوعرقوب"},
        {id:"64",id2:"64",key:"nabeul",value:"1",text:" الحمامات"},
        {id:"65",id2:"65",key:"Bizerte",value:"Bizerte Nord",text:"بنزرت الشمالية"},
        {id:"66",id2:"66",key:"Bizerte",value:"Zarzouna",text:"جرزونة"},
        {id:"67",id2:"67",key:"Bizerte",value:"Bizerte Sud",text:"بنزرت الجنوبية"},
        {id:"68",id2:"68",key:"Bizerte",value:"Sejnane",text:"سجنان"},
        {id:"69",id2:"69",key:"Bizerte",value:"1",text:"جومين"},
        {id:"70",id2:"70",key:"Bizerte",value:"Mateur",text:"ماطر"},
        {id:"71",id2:"71",key:"Bizerte",value:"1",text:"غزالة"},
        {id:"72",id2:"72",key:"Bizerte",value:"Menzel Bourguiba",text:"منزل بورقيبة"},
        {id:"73",id2:"73",key:"Bizerte",value:"Tinja",text:"تينجة"},
        {id:"74",id2:"74",key:"Bizerte",value:"1",text:"أوتيك"},
        {id:"75",id2:"75",key:"Bizerte",value:"Ghar El Melh",text:"غار الملح"},
        {id:"76",id2:"76",key:"Bizerte",value:"Menzel Jemil",text:"منزل جميل"},
        {id:"77",id2:"77",key:"Bizerte",value:"El Alia",text:"العالية"},
        {id:"78",id2:"78",key:"Bizerte",value:"1",text:"رأس الجبل"},
        {id:"79",id2:"79",key:"Zaghouan",value:"Zaghouan",text:"زغوان"},
        {id:"80",id2:"80",key:"Zaghouan",value:"1",text:"الزريبة"},
        {id:"81",id2:"81",key:"Zaghouan",value:"1",text:"بئر مشارقة"},
        {id:"82",id2:"82",key:"Zaghouan",value:"El Fahs",text:"الفحص"},
        {id:"83",id2:"83",key:"Zaghouan",value:"Nadhour",text:"الناظور"},
        {id:"84",id2:"84",key:"Zaghouan",value:"1",text:"صواف"},
        {id:"85",id2:"85",key:"Sousse",value:"Sousse Médina",text:"سوسة المدينة"},
        {id:"86",id2:"86",key:"sousse",value:"1",text:"الزاوية القصيبة الثريات"},
        {id:"87",id2:"87",key:"Sousse",value:"Sousse Riadh",text:"سوسة الرياض"},
        {id:"88",id2:"88",key:"sousse",value:"1",text:"سوسة جوهرة"},
        {id:"89",id2:"89",key:"sousse",value:"1",text:"سوسة سيدي عبد الحميد"},
        {id:"90",id2:"90",key:"Sousse",value:"Hammam Sousse",text:"حمام سوسة"},
        {id:"91",id2:"91",key:"Sousse",value:"Akouda",text:"أكودة"},
        {id:"92",id2:"92",key:"sousse",value:"1",text:"القلعة الكبرى"},
        {id:"93",id2:"93",key:"sousse",value:"1",text:"سيدي بوعلي"},
        {id:"94",id2:"94",key:"Sousse",value:"Hergla",text:"هرقلة"},
        {id:"95",id2:"95",key:"Sousse",value:"Enfida",text:"النفيضة"},
        {id:"96",id2:"96",key:"Sousse",value:"Bouficha",text:"بوفيشة"},
        {id:"97",id2:"97",key:"sousse",value:"1",text:"كندار"},
        {id:"98",id2:"98",key:"Sousse",value:"Sidi El Hani",text:"سيدي الهاني"},
        {id:"99",id2:"99",key:"Sousse",value:"M'saken",text:"مساكن"},
        {id:"100",id2:"100",key:"sousse",value:"1",text:"القلعة الصغرى"},
        {id:"101",id2:"101",key:"Monastir",value:"1",text:"المنستيـر"},
        {id:"102",id2:"102",key:"Monastir",value:"1",text:" الوردانيـن"},
        {id:"103",id2:"103",key:"Monastir",value:"1",text:"الساحليـن"},
        {id:"104",id2:"104",key:"Monastir",value:"1",text:" زرمديـن"},
        {id:"105",id2:"105",key:"Monastir",value:"1",text:" بنـي حسان"},
        {id:"106",id2:"106",key:"Monastir",value:"1",text:" جمـال"},
        {id:"107",id2:"107",key:"Monastir",value:"1",text:" بنبلة"},
        {id:"108",id2:"108",key:"Monastir",value:"1",text:" المكنين"},
        {id:"109",id2:"109",key:"Monastir",value:"1",text:" البقالطة"},
        {id:"110",id2:"110",key:"Monastir",value:"1",text:" طبلبة"},
        {id:"111",id2:"111",key:"Monastir",value:"1",text:" قصر هلال"},
        {id:"112",id2:"112",key:"Monastir",value:"1",text:"قصيبة المديوني"},
        {id:"113",id2:"113",key:"Monastir",value:"1",text:" صيادة لمطة بوحجر"},
        {id:"114",id2:"114",key:"Mahdia",value:"Mahdia",text:"المهدية"},
        {id:"115",id2:"115",key:"Mahdia",value:"1",text:"بومرداس"},
        {id:"116",id2:"116",key:"Mahdia",value:"Ouled Chamekh",text:"أولاد الشامخ"},
        {id:"117",id2:"117",key:"Mahdia",value:"Chorbane",text:"شربان"},
        {id:"118",id2:"118",key:"Mahdia",value:"Hebira",text:"هبيرة"},
        {id:"119",id2:"119",key:"Mahdia",value:"Essouassi",text:"السواسي"},
        {id:"120",id2:"120",key:"Mahdia",value:"El Jem",text:"الجم"},
        {id:"121",id2:"121",key:"Mahdia",value:"Chebba",text:"الشابة"},
        {id:"122",id2:"122",key:"Mahdia",value:"Melloulèche",text:"ملولش"},
        {id:"123",id2:"123",key:"Mahdia",value:"Sidi Alouane",text:"سيدي علوان"},
        {id:"124",id2:"124",key:"Mahdia",value:"Ksour Essef",text:"قصور الساف"},
        {id:"125",id2:"125",key:"sfax",value:"1",text:"صفاقس المدينة"},
        {id:"126",id2:"126",key:"Sfax",value:"Sfax Ouest",text:"صفاقس الغربية"},
        {id:"127",id2:"127",key:"Sfax",value:"Sakiet Ezzit",text:"ساقية الزيت"},
        {id:"128",id2:"128",key:"sfax",value:"1",text:" ساقية الداير"},
        {id:"129",id2:"129",key:"Sfax",value:"Sfax Sud",text:"صفاقس الجنوبية"},
        {id:"130",id2:"130",key:"Sfax",value:"Thyna",text:"طينة"},
        {id:"131",id2:"131",key:"sfax",value:"1",text:"عقارب"},
        {id:"132",id2:"132",key:"Sfax",value:"Jebiniana",text:"جبنيانة"},
        {id:"133",id2:"133",key:"Sfax",value:"El Amra",text:"العامرة"},
        {id:"134",id2:"134",key:"Sfax",value:"El Hencha",text:"الحنشة"},
        {id:"135",id2:"135",key:"Sfax",value:"Menzel Chaker",text:"منزل شاكر"},
        {id:"136",id2:"136",key:"Sfax",value:"Graïba",text:"الغريبة"},
        {id:"137",id2:"137",key:"sfax",value:"1",text:"بئر علي بن خليفة"},
        {id:"138",id2:"138",key:"Sfax",value:"Skhira",text:"الصخيرة"},
        {id:"139",id2:"139",key:"Sfax",value:"Mahrès",text:"المحرس"},
        {id:"140",id2:"140",key:"sfax",value:"1",text:"قـرقنـة"},
        {id:"141",id2:"141",key:"Beja",value:"Béja Nord",text:"باجة الشمالية"},
        {id:"142",id2:"142",key:"Beja",value:"Béja Sud",text:"باجة الجنوبية"},
        {id:"143",id2:"143",key:"Beja",value:"Amdoun",text:"عمدون"},
        {id:"144",id2:"144",key:"Beja",value:"Nefza",text:"نفزة"},
        {id:"145",id2:"145",key:"Beja",value:"Téboursouk",text:"تبرسق"},
        {id:"146",id2:"146",key:"Beja",value:"Thibar",text:"تيبار"},
        {id:"147",id2:"147",key:"Beja",value:"Testour",text:"تستور"},
        {id:"148",id2:"148",key:"Beja",value:"Goubellat",text:"قبلاط"},
        {id:"149",id2:"149",key:"Beja",value:"Medjez el-Bab",text:"مجاز الباب"},
        {id:"150",id2:"150",key:"Jendouba",value:"1",text:"جنـدوبة"},
        {id:"151",id2:"151",key:"Jendouba",value:"1",text:" جنـدوبة الشمالية"},
        {id:"152",id2:"152",key:"Jendouba",value:"1",text:"   بوسالم"},
        {id:"153",id2:"153",key:"Jendouba",value:"1",text:" طبرقـة"},
        {id:"154",id2:"154",key:"Jendouba",value:"1",text:" عين دراهم"},
        {id:"155",id2:"155",key:"Jendouba",value:"1",text:" فرنانة"},
        {id:"156",id2:"156",key:"Jendouba",value:"1",text:" غار الدماء"},
        {id:"157",id2:"157",key:"Jendouba",value:"1",text:" وادي مليز"},
        {id:"158",id2:"158",key:"Jendouba",value:"1",text:"  بلطة  بوعوان"},
        {id:"159",id2:"159",key:"Kef",value:"Kef Est",text:"الكاف الغربية"},
        {id:"160",id2:"160",key:"kef",value:"1",text:" الكاف الشرقية"},
        {id:"161",id2:"161",key:"kef",value:"1",text:" نبـر"},
        {id:"162",id2:"162",key:"kef",value:"1",text:" ساقية سيدي يوسف  "},
        {id:"163",id2:"163",key:"Kef",value:"Tajerouine",text:"تاجروين"},
        {id:"164",id2:"164",key:"kef",value:"1",text:" قلعة سنان"},
        {id:"165",id2:"165",key:"kef",value:"1",text:" القلعة الخصبة"},
        {id:"166",id2:"166",key:"kef",value:"1",text:" الجريصة"},
        {id:"167",id2:"167",key:"kef",value:"1",text:" القصور"},
        {id:"168",id2:"168",key:"kef",value:"1",text:" الدهماني"},
        {id:"169",id2:"169",key:"Kef",value:"Sers",text:"السرس"},
        {id:"170",id2:"170",key:"Siliana",value:"Siliana Nord",text:"سليانة الشمالية"},
        {id:"171",id2:"171",key:"Siliana",value:"Siliana Sud",text:"سليانة الجنوبية"},
        {id:"172",id2:"172",key:"Siliana",value:"Bou Arada",text:"بوعرادة"},
        {id:"173",id2:"173",key:"Siliana",value:"Gaâfour",text:"قعفور"},
        {id:"174",id2:"174",key:"Siliana",value:"El Aroussa",text:"العروسة"},
        {id:"175",id2:"175",key:"Siliana",value:"El Krib",text:"الكريب"},
        {id:"176",id2:"176",key:"siliana",value:"1",text:"بورويس"},
        {id:"177",id2:"177",key:"Siliana",value:"Makthar",text:"مكثر"},
        {id:"178",id2:"178",key:"Siliana",value:"Rouhia",text:"الروحية"},
        {id:"179",id2:"179",key:"siliana",value:"1",text:"كسرى"},
        {id:"180",id2:"180",key:"Siliana",value:"Bargou",text:"برقو"},
        {id:"181",id2:"181",key:"Kairouan",value:"Kairouan Nord",text:"القيروان الشمالية"},
        {id:"182",id2:"182",key:"Kairouan",value:"Kairouan Sud",text:"القيروان الجنوبية"},
        {id:"183",id2:"183",key:"Kairouan",value:"1",text:" الشبيكة"},
        {id:"184",id2:"184",key:"Kairouan",value:"1",text:" السبيخة"},
        {id:"185",id2:"185",key:"Kairouan",value:"1",text:" الوسلاتية"},
        {id:"186",id2:"186",key:"Kairouan",value:"1",text:" حفوز"},
        {id:"187",id2:"187",key:"Kairouan",value:"1",text:" العلا"},
        {id:"188",id2:"188",key:"Kairouan",value:"1",text:" حاجب العيون"},
        {id:"189",id2:"189",key:"Kairouan",value:"1",text:" نصر الله"},
        {id:"190",id2:"190",key:"Kairouan",value:"1",text:" الشراردة"},
        {id:"191",id2:"191",key:"Kairouan",value:"1",text:" بوحجلة"},
        {id:"192",id2:"192",key:"Sidi Bouzid",value:"Sidi Bouzid Ouest",text:"سيدي بوزيد الغربية"},
        {id:"193",id2:"193",key:"Sidi Bouzid",value:"1",text:" سيدي بوزيد الشرقية"},
        {id:"194",id2:"194",key:"Sidi Bouzid",value:"1",text:" جلمة"},
        {id:"195",id2:"195",key:"Sidi Bouzid",value:"1",text:" سبالة أولاد عسكر"},
        {id:"196",id2:"196",key:"Sidi Bouzid",value:"1",text:"   بئر الحفي"},
        {id:"197",id2:"197",key:"Sidi Bouzid",value:"1",text:" سيدي علي بن عون"},
        {id:"198",id2:"198",key:"Sidi Bouzid",value:"1",text:" منزل بوزيان"},
        {id:"199",id2:"199",key:"Sidi Bouzid",value:"1",text:" المكناسي"},
        {id:"200",id2:"200",key:"Sidi Bouzid",value:"1",text:" سوق الجديد"},
        {id:"201",id2:"201",key:"Sidi Bouzid",value:"1",text:" المزونة"},
        {id:"202",id2:"202",key:"Sidi Bouzid",value:"Regueb",text:"الرقاب"},
        {id:"203",id2:"203",key:"Sidi Bouzid",value:"1",text:" أولاد حفوز"},
        {id:"204",id2:"204",key:"Kasserine",value:"Kasserine Nord",text:"القصرين الشمالية"},
        {id:"205",id2:"205",key:"Kasserine",value:"1",text:" القصرين الجنوبية"},
        {id:"206",id2:"206",key:"Tunis",value:"Ezzouhour",text:"الزهور"},
        {id:"207",id2:"207",key:"Kasserine",value:"1",text:" حاسي الفريد"},
        {id:"208",id2:"208",key:"Kasserine",value:"1",text:" سبيطلة"},
        {id:"209",id2:"209",key:"Kasserine",value:"1",text:" سبيبة"},
        {id:"210",id2:"210",key:"Kasserine",value:"1",text:" جدليان"},
        {id:"211",id2:"211",key:"Kasserine",value:"1",text:" العيون"},
        {id:"212",id2:"212",key:"Kasserine",value:"1",text:" تالة"},
        {id:"213",id2:"213",key:"Kasserine",value:"1",text:" حيدرة"},
        {id:"214",id2:"214",key:"Kasserine",value:"1",text:" فوسانة"},
        {id:"215",id2:"215",key:"Kasserine",value:"1",text:" فريانة"},
        {id:"216",id2:"216",key:"Kasserine",value:"1",text:" ماجل بلعباس"},
        {id:"217",id2:"217",key:"Gabes",value:"1",text:"قابـس المدينة"},
        {id:"218",id2:"218",key:"Gabes",value:"1",text:"قابـس الغربية"},
        {id:"219",id2:"219",key:"Gabes",value:"1",text:"  قابـس الجنوبية"},
        {id:"220",id2:"220",key:"Gabes",value:"1",text:" غنوش"},
        {id:"221",id2:"221",key:"Gabes",value:"1",text:" المطوية"},
        {id:"222",id2:"223",key:"Gabes",value:"1",text:" الحامة"},
        {id:"223",id2:"224",key:"Gabes",value:"1",text:" مطماطة"},
        {id:"224",id2:"225",key:"Gabes",value:"1",text:" مطماطة الجديدة"},
        {id:"225",id2:"226",key:"Gabes",value:"Mareth",text:"مارث"},
        {id:"226",id2:"227",key:"Medenine",value:"1",text:"مدنيـن الشمالية"},
        {id:"227",id2:"228",key:"Medenine",value:"Médenine Sud",text:"مدنين الجنوبية"},
        {id:"228",id2:"229",key:"Medenine",value:"1",text:" بني خداش"},
        {id:"229",id2:"230",key:"Medenine",value:"1",text:" بن قردان"},
        {id:"230",id2:"231",key:"Medenine",value:"1",text:" جرجيس"},
        {id:"231",id2:"232",key:"Medenine",value:"1",text:" جربة حومة السوق"},
        {id:"232",id2:"233",key:"Medenine",value:"1",text:" جربة ميدون"},
        {id:"233",id2:"234",key:"Medenine",value:"1",text:" جربة أجيم"},
        {id:"234",id2:"235",key:"Medenine",value:"Sidi Makhlouf",text:"سيدي مخلوف"},
        {id:"235",id2:"236",key:"Gafsa",value:"Gafsa Nord",text:"قفصة الشمالية"},
        {id:"236",id2:"237",key:"Gafsa",value:"1",text:" سيدي عيش"},
        {id:"237",id2:"238",key:"Gafsa",value:"1",text:" القصر"},
        {id:"238",id2:"239",key:"Gafsa",value:"Gafsa Sud",text:"قفصة الجنوبية"},
        {id:"239",id2:"240",key:"Gafsa",value:"1",text:" أم العرائس"},
        {id:"240",id2:"241",key:"Gafsa",value:"1",text:" الرديف"},
        {id:"241",id2:"242",key:"Gafsa",value:"1",text:" المتلوي"},
        {id:"242",id2:"243",key:"Gafsa",value:"1",text:" المظيلة"},
        {id:"243",id2:"244",key:"Gafsa",value:"1",text:" القطار"},
        {id:"244",id2:"245",key:"Gafsa",value:"1",text:" بلخير"},
        {id:"245",id2:"256",key:"Tataouine",value:"1",text:"غمراسن"},
        {id:"246",id2:"247",key:"Tozeur",value:"Tozeur",text:"توزر"},
        {id:"247",id2:"248",key:"Tozeur",value:"Degache",text:"دقاش"},
        {id:"248",id2:"249",key:"Tozeur",value:"Tameghza",text:"تمغزة"},
        {id:"249",id2:"250",key:"Tozeur",value:"Nefta",text:"نفطة"},
        {id:"250",id2:"251",key:"Tozeur",value:"Hazoua",text:"حزوة"},
        {id:"251",id2:"252",key:"Tataouine",value:"Tataouine Nord",text:"تطاوين الشمالية"},
        {id:"252",id2:"253",key:"Tataouine",value:"Tataouine Sud",text:"تطاوين الجنوبية"},
        {id:"253",id2:"254",key:"Tataouine",value:"1",text:"الصمار"},
        {id:"254",id2:"255",key:"Tataouine",value:"1",text:"البئر الأحمر"},
        {id:"255",id2:"256",key:"Tataouine",value:"Ghomrassen",text:"غمراسن"},
        {id:"256",id2:"257",key:"Tataouine",value:"1",text:"ذهيبة"},
        {id:"257",id2:"258",key:"Tataouine",value:"Remada",text:"رمادة"},
        {id:"258",id2:"259",key:"Kebili",value:"Kébili Sud",text:"قبلي الجنوبية"},
        {id:"259",id2:"260",key:"Kebili",value:"Kébili Nord",text:"قبلي الشمالية"},
        {id:"260",id2:"261",key:"Kebili",value:"Souk Lahad",text:"سوق الأحد"},
        {id:"261",id2:"262",key:"Kebili",value:"Douz Nord",text:"دوز الشمالية"},
        {id:"262",id2:"263",key:"Kebili",value:"Douz Sud",text:"دوز الجنوبية"},
        {id:"263",id2:"264",key:"Kebili",value:"Faouar",text:"الفوار"}
    ]

}
 
export default GConf 