const JapanTrans = {
  translation : {
    mainPageLanding : {
      mainTitle: 'アビエドシステム',
      adsText: {
        mainText: '様々なビジネス分野での日常業務のデジタル化は、それらの発展と品質向上に貢献し、労力を削減し、時間とリソースを節約することに役立ちます。したがって、Abiadプラットフォームの目標は、さまざまな管理システムを通じてチュニジアのすべてのセクターでビジネス環境を向上させることです。',
        subText: 'Abiadプラットフォームでは、さまざまな分野で多くのシステムを提供しています：'
      },
      categoriesName: {
        ptvente: '販売ポイントシステム：',
        health: '健康システム：',
        education: '教育システム：',
        restaurant: 'レストランサービスシステム：',
        transport: '交通およびモビリティサービスシステム：',
        handmade: '手作りサービスシステム：',
        construction: '不動産サービスシステム：',
        wedding: '結婚式サービスシステム：',
        sport: 'スポーツシステム：',
        other: 'その他のシステム：',
      },
      systemNames: {
          docteur: "医師の診療所管理システム",
          pharmacie: "薬局管理システム",
          clinique: "私立クリニック管理システム",
          labo: "医療検査室管理システム",
          infirmier: "看護師クリニック管理システム",
          centre: "医療センター管理システム",
          admin_s_hospital: "病院管理システム",
          admin_s_csb: "健康センター管理システム",
          garderie: "保育園管理システム",
          ecole: "私立学校管理システム",
          lycee: "私立高校管理システム",
          universite: "私立大学管理システム",
          formation: "専門教育センター管理システム",
          impremerie: "印刷所管理システム",
          librairie: "書店管理システム",
          admin_e_centre: "教育センター管理システム",
          admin_e_biblio: "公共図書館管理システム",
          admin_e_ecole: "公立学校管理システム",
          admin_e_lycee: "公立高校管理システム",
          admin_e_universite: "公立大学管理システム",
          admin_e_ss: "公共サービス管理システム",
          transporteur: "運送会社管理システム",
          autoecole: "自動車学校管理システム",
          cafe: "カフェ管理システム",
          restaurant: "レストラン管理システム",
          magazin: "店舗管理システム",
          boulengerie: "パン屋管理システム",
          boucherie: "肉屋管理システム",
          fruiterie: "果物屋管理システム",
          patesserie: "菓子店管理システム",
          epicerie: "食料品店管理システム",
          electromenager: "家電店管理システム",
          meublerie: "家具店管理システム",
          location: "レンタカー管理システム",
          parking: "駐車場管理システム",
          qiosque: "キオスク管理システム",
          mecanicien: "自動車修理工場管理システム",
          courtier: "不動産業者管理システム",
          contracteur: "建設会社管理システム",
          architecture: "建築事務所管理システム",
          quincaillerie: "金物店管理システム",
          forgeron: "鍛冶屋管理システム",
          menuisier: "大工屋管理システム",
          peinture: "塗装会社管理システム",
          electricien: "電気工事会社管理システム",
          plombier: "配管工事会社管理システム",
          cristalerie: "ガラス工房管理システム",
          marbrerie: "石工場管理システム",
          coiffure: "美容院管理システム",
          boutique: "洋服店管理システム",
          salle_marriage: "結婚式場管理システム",
          orchestre: "オーケストラ管理システム",
          chef: "料理人の予約管理システム",
          photographe: "写真スタジオ管理システム",
          fourniture_marriage: "結婚式用品レンタル管理システム",
          bijouterie: "宝石店管理システム",
          gym: "フィットネスジム管理システム",
          pyscine: "プール管理システム",
          stade: "スタジアム管理システム",
          cinema: "映画館管理システム",
          theatre: "劇場管理システム",
          musee: "美術館管理システム",
          admin_c_mj: "青少年センター管理システム",
          admin_c_mc: "文化センター管理システム",
          admin_c_me: "子供クラブ管理システム",
          avocat: "法律事務所管理システム",
          depot: "卸売店管理システム",
          comptable: "会計事務所管理システム",
          socite: "企業管理システム",
          bank: "銀行管理システム",
          admin_f_poste: "郵便局管理システム",
          admin_f_rf: "財務収入管理システム",
          veterinaire: "獣医クリニック管理システム",
          fourragerie: "ペットフード販売店管理システム",
          agritools: "農業用具販売店管理システム",
          farmer: "農産物販売店管理システム",
          hotels: "ホテル管理システム",
          vg_agence: "旅行代理店管理システム",
          admin_a_mu: "音楽学校管理システム",
          admin_a_police: "警察署管理システム",
          admin_a_ar: "消防署管理システム",
          admin_a_court: "裁判所管理システム",
          admin_a_mosq: "モスク管理システム",
          agriToolsOld: "農業機械店管理システム"
      },
    },
    systemLandingPage:{
      adsText: {
        depot: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم .  كما يقدم لك واجهة رسومية (caisse) لتسجيل مبيعات الشاحنات و طباعة الفواتير.  بالإضافة إلي مجموعة من الأدوات  (مثل واجهة  تسجيل الطلبات    commandes  )  التي تساعدك علي أداء عملك بجودة أفضل',
        magazin: 'نظام إداراة المغازاة و نقاط البيع يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        fruiterie: 'نظام إداراة نقاط  بيع الخضر  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        boucherie: 'نظام إداراة المخابز  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        boulengerie: 'نظام إداراة نقاط بيع اللحوم  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        patesserie: 'نظام إداراة نقاط بيع الحلويات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        epicerie: 'نظام إداراة البقالات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        pharmacie: 'نظام إداراة نقاط الصيدليات يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        librairie: 'نظام إداراة الكتبيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        boutique: 'نظام إداراة نقاط بيع الملابس  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        quincaillerie: 'نظام إداراة Quancaillerie  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        electromenager: 'نظام إداراة نقاط بيع الالكترونيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        meublerie: 'نظام إداراة نقاط ابيع الاثاث  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        docteur: ' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما متصلا مع مرضاك  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
        infirmier: ' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما بإتصال بالمرضي  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
        clinique: 'نظام إدارة المصحات يساعدك علي تتبع جميع العمليات و الأنشطة التي تحدث داخل مصحتك . كما يساعدك علي متابعة مواعيد العمليات و تقارير يومية لفهم تطور الحالة الصحية للمرضي, بالإضافة لجملة من الأدوات الرقمية مثل واجهة تسجيل حضور فريق العمل و واجهة متابعة مريض معين  ',
        labo: 'يساعدك نظام إدارة المخابر الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
        centre: 'يساعدك نظام إدارة المراكز الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
        garderie: 'نظام إدارة  رياض الأطفال يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
        autoecole: 'يساعدك نظام إدارة    مدارس تعليم السياقة علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
        ecole: 'نظام إدارة المدارس الخاصة يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
        formation: 'نظام إدارة  مراكز التكوين   يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
        lycee: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        universite: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        restaurant: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        cafe: 'نكانك صاحب مطعم أبيض يوفرلك نظام إدارة و مراقبة و متابعة للمطعم وإلا العمل متاعك , من خلال بيئة إفتراضية تخليك ديما متصل بالزبائن متاعك لمعرفة ردود أفعالهم علي الخدمات اللي تقدمها ',
        hotel: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        transporteur: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        vg_agence: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        courtier: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        impremerie: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        avocat: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        comptable: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        menuisier: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        forgeron: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        cristal: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        marbre: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        electricien: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        peinture: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        contracteur: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        architecture: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        parking: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        qiosque: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        location: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        photographe: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        salon_marriage: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        fourniture_marriage: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        coiffure: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        gym: '   نظام إدارة  قاعات الرياضة هو نظام إدارة و  محاسبة و تواصل مع مشتركيك و مراقبة أداءهم و تطور نشاطهم الرياضي من خلال خلق فضاء للتفاعل إفتراضيا مع القاعة لتقييم خدماتها ...',
        pyscine: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        stade: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        cinema: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        theatre: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        farm: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        socite: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل'
                
      ,
      },
      subscribeTo: '今すぐ購読',
      goToSystem: 'システムに移動',
      prosConsText: '複数の利点があります',
      prosConsData: {
        connected: {
          smallText: '接続された',
          largeText: 'システムは、包括的でインタラクティブなディレクトリを介して常に顧客と接続されています。これにより、顧客はスマートにあなたとコミュニケーションを取ることができます。また、チーム全体とのコネクションを保ち、彼らの作業を追跡し、タスクを監視するための独自のコントロールパネルを介しています。',
        },
        easy: {
          smallText: '簡単',
          largeText: 'システムはシンプルでありながら効果的であり、現在のバージョンではシンプルに構成され、整理されています。',
        },
        helpful: {
          smallText: '役に立つ',
          largeText: 'システムは管理ツールだけでなく、追加のツールを使用して、業務の知識を向上させるためにもあなたを支援します。たとえば、薬物辞典、疾患百科事典、人体の3次元モデルなどがあります。',
        },
        progressed: {
          smallText: '進化した',
          largeText: 'ホワイトシステムはクラウドベースのシステムであり、個人用コンピュータにインストールされているシステムではありません。したがって、クリエイティブなホワイトチームは、伝統的な「硬直した」システムとは異なり、開発し、適切かつ快適に使いやすくするために取り組んでいます。',
        },
        supported: {
          smallText: 'サポートされた',
          largeText: 'システムには包括的なユーザーガイドと、システムを使用し始めるときに思いつくすべての質問に正確に答えるためのサポートが付属しています。すべての機能に対する詳細な説明があるため、システムの使用方法を学ぶために専門家に連絡する必要はありません。',
        },
        smart: {
          smallText: 'スマート',
          largeText: 'システムは、ルーチンおよび繰り返し操作を自動化するアルゴリズムを準備し、ユーザーの行動に基づいてルーチンの決定を行うことで、システムに知的な属性を付与します。',
        },
      },
      subscribeNow: '今すぐ購読して、システムを試すための2か月間を無料で入手',
      openVersion: 'オープンバージョン',
      mediumVersion: '中規模企業向け',
      bigVersion: '大規模企業向け',
      currencyText: 'USD',
      fullYearText: '1年間',
      prosItemList: {
        1: 'システムを試すための2か月間無料',
        2: '無制限のリクエスト',
        3: 'ディレクトリに無料で表示',
        4: 'ユニークな識別子（PID）',
        5: 'さまざまな無料デジタルツール',
        6: 'スマートホワイトデバイスとの接続機能',
        7: '銀行カードとの接続機能',
        8: 'インテリジェントで自動化されたデータ解析',
        9: '自動データバックアップ',
      },
      subscribeBtn: '今すぐ購読'
    },
    bottomCard:{
      footerVision:'アビヤドプラットフォームのビジョンは何ですか？',
      footerHow:'プラットフォームの使用方法は？',
      footerWho:'私たちは誰ですか？',
    }
  },
    
}
export default JapanTrans