const FrancaisTrans = {
  translation : {
    mainPageLanding : {
      mainTitle: 'Systèmes Abyedh',
      adsText: {
        mainText: "La numérisation de diverses opérations quotidiennes dans divers domaines d'activité contribuera à leur développement et à leur amélioration vers une meilleure qualité, contribuant ainsi à réduire les efforts et à économiser du temps et des ressources. Par conséquent, la plateforme Abiad vise à améliorer l'environnement commercial en Tunisie dans tous les secteurs grâce à divers systèmes administratifs.",
        subText: "La plateforme Abiad propose de nombreux systèmes dans divers domaines :"
      },
      categoriesName: {
        ptvente: 'Systèmes de point de vente :',
        health: 'Systèmes de santé :',
        education: 'Systèmes éducatifs :',
        restaurant: 'Systèmes de services de restauration :',
        transport: 'Systèmes de services de transport et de mobilité :',
        handmade: 'Systèmes de services artisanaux :',
        construction: 'Systèmes de services immobiliers :',
        wedding: 'Systèmes de services de mariage :',
        sport: 'Systèmes sportifs :',
        other: 'Autres systèmes :',
      },
      systemNames : {
        docteur: "Système de gestion de clinique",
        pharmacie: "Système de gestion de pharmacie",
        clinique: "Système de gestion de clinique privée",
        labo: "Système de gestion de laboratoire médical",
        infirmier: "Système de gestion de clinique infirmière",
        centre: "Système de gestion de centre médical",
        admin_s_hospital: "Système de gestion d'hôpital",
        admin_s_csb: "Système de gestion des centres de santé",
        garderie: "Système de gestion de garderie",
        ecole: "Système de gestion d'école privée",
        lycee: "Système de gestion de lycée privé",
        universite: "Système de gestion d'université privée",
        formation: "Système de gestion de centres de formation spécialisée",
        impremerie: "Système de gestion d'imprimerie",
        librairie: "Système de gestion de librairie",
        admin_e_centre: "Système de gestion de centres de formation",
        admin_e_biblio: "Système de gestion de bibliothèques publiques",
        admin_e_ecole: "Système de gestion d'écoles publiques",
        admin_e_lycee: "Système de gestion de lycées publics",
        admin_e_universite: "Système de gestion d'universités publiques",
        admin_e_ss: "Système de gestion des services publics",
        transporteur: "Système de gestion d'agence de transport",
        autoecole: "Système de gestion d'auto-école",
        cafe: "Système de gestion de café",
        restaurant: "Système de gestion de restaurant",
        magazin: "Système de gestion de magasin",
        boulengerie: "Système de gestion de boulangerie",
        boucherie: "Système de gestion de boucherie",
        fruiterie: "Système de gestion de fruits et légumes",
        patesserie: "Système de gestion de pâtisserie",
        epicerie: "Système de gestion d'épicerie",
        electromenager: "Système de gestion de vente d'électroménagers",
        meublerie: "Système de gestion de magasin de meubles",
        location: "Système de gestion de location de voitures",
        parking: "Système de gestion de parking",
        qiosque: "Système de gestion de station-service",
        mecanicien: "Système de gestion de garage mécanique",
        courtier: "Système de gestion d'agence immobilière",
        contracteur: "Système de gestion de bureau d'entrepreneur",
        architecture: "Système de gestion de bureau d'architecte",
        quincaillerie: "Système de gestion de quincaillerie",
        forgeron: "Système de gestion de forge",
        menuisier: "Système de gestion de menuiserie",
        peinture: "Système de gestion de peinture",
        electricien: "Système de gestion de service électrique",
        plombier: "Système de gestion de plomberie",
        cristalerie: "Système de gestion de verrerie",
        marbrerie: "Système de gestion de marbrerie",
        coiffure: "Système de gestion de salon de coiffure",
        boutique: "Système de gestion de boutique de vêtements",
        salle_marriage: "Système de gestion de salle de mariage",
        orchestre: "Système de gestion d'orchestre",
        chef: "Système de gestion de rendez-vous de chef cuisinier",
        photographe: "Système de gestion de studio de photographie",
        fourniture_marriage: "Système de gestion de location de matériel de mariage",
        bijouterie: "Système de gestion de bijouterie",
        gym: "Système de gestion de salle de sport",
        pyscine: "Système de gestion de piscine",
        stade: "Système de gestion de stade",
        cinema: "Système de gestion de cinéma",
        theatre: "Système de gestion de théâtre",
        musee: "Système de gestion de musée",
        admin_c_mj: "Système de gestion de maison de jeunesse",
        admin_c_mc: "Système de gestion de maison de la culture",
        admin_c_me: "Système de gestion de clubs pour enfants",
        avocat: "Système de gestion de cabinet d'avocats",
        depot: "Système de gestion de points de vente en gros",
        comptable: "Système de gestion de cabinet comptable",
        socite: "Système de gestion d'entreprise",
        bank: "Système de gestion de banque",
        admin_f_poste: "Système de gestion de bureaux de poste",
        admin_f_rf: "Système de gestion de recettes financières",
        veterinaire: "Système de gestion de clinique vétérinaire",
        fourragerie: "Système de gestion de points de vente d'aliments pour animaux",
        agritools: "Système de gestion de points de vente d'outils agricoles",
        farmer: "Système de gestion de points de vente de produits agricoles",
        hotels: "Système de gestion d'hôtel",
        vg_agence: "Système de gestion d'agence de voyage",
        admin_a_mu: "Système de gestion d'école de musique",
        admin_a_police: "Système de gestion de commissariat de police",
        admin_a_ar: "Système de gestion de caserne de pompiers",
        admin_a_court: "Système de gestion de tribunal",
        admin_a_mosq: "Système de gestion de mosquée",
        agriToolsOld: "Système de gestion de magasin d'outils agricoles"
    },
    },
    systemLandingPage:{
      adsText: {
        depot: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم .  كما يقدم لك واجهة رسومية (caisse) لتسجيل مبيعات الشاحنات و طباعة الفواتير.  بالإضافة إلي مجموعة من الأدوات  (مثل واجهة  تسجيل الطلبات    commandes  )  التي تساعدك علي أداء عملك بجودة أفضل',
        magazin: 'نظام إداراة المغازاة و نقاط البيع يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        fruiterie: 'نظام إداراة نقاط  بيع الخضر  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        boucherie: 'نظام إداراة المخابز  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        boulengerie: 'نظام إداراة نقاط بيع اللحوم  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        patesserie: 'نظام إداراة نقاط بيع الحلويات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        epicerie: 'نظام إداراة البقالات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        pharmacie: 'نظام إداراة نقاط الصيدليات يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        librairie: 'نظام إداراة الكتبيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        boutique: 'نظام إداراة نقاط بيع الملابس  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        quincaillerie: 'نظام إداراة Quancaillerie  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        electromenager: 'نظام إداراة نقاط بيع الالكترونيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        meublerie: 'نظام إداراة نقاط ابيع الاثاث  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        docteur: ' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما متصلا مع مرضاك  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
        infirmier: ' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما بإتصال بالمرضي  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
        clinique: 'نظام إدارة المصحات يساعدك علي تتبع جميع العمليات و الأنشطة التي تحدث داخل مصحتك . كما يساعدك علي متابعة مواعيد العمليات و تقارير يومية لفهم تطور الحالة الصحية للمرضي, بالإضافة لجملة من الأدوات الرقمية مثل واجهة تسجيل حضور فريق العمل و واجهة متابعة مريض معين  ',
        labo: 'يساعدك نظام إدارة المخابر الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
        centre: 'يساعدك نظام إدارة المراكز الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
        garderie: 'نظام إدارة  رياض الأطفال يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
        autoecole: 'يساعدك نظام إدارة    مدارس تعليم السياقة علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
        ecole: 'نظام إدارة المدارس الخاصة يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
        formation: 'نظام إدارة  مراكز التكوين   يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
        lycee: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        universite: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        restaurant: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        cafe: 'نكانك صاحب مطعم أبيض يوفرلك نظام إدارة و مراقبة و متابعة للمطعم وإلا العمل متاعك , من خلال بيئة إفتراضية تخليك ديما متصل بالزبائن متاعك لمعرفة ردود أفعالهم علي الخدمات اللي تقدمها ',
        hotel: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        transporteur: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        vg_agence: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        courtier: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        impremerie: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        avocat: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        comptable: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        menuisier: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        forgeron: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        cristal: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        marbre: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        electricien: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        peinture: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        contracteur: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        architecture: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        parking: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        qiosque: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        location: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        photographe: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        salon_marriage: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        fourniture_marriage: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        coiffure: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        gym: '   نظام إدارة  قاعات الرياضة هو نظام إدارة و  محاسبة و تواصل مع مشتركيك و مراقبة أداءهم و تطور نشاطهم الرياضي من خلال خلق فضاء للتفاعل إفتراضيا مع القاعة لتقييم خدماتها ...',
        pyscine: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        stade: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        cinema: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        theatre: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        farm: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        socite: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل'
                
      ,
      },
      subscribeTo: 'Abonnez-vous  ',
      goToSystem: 'Accéder  ',
      prosConsText: 'Présente plusieurs avantages',
      prosConsData: {
        connected: {
          smallText: 'Connecté',
          largeText: 'Le système vous maintient constamment connecté avec vos clients grâce à un répertoire complet et interactif qui leur permet de communiquer avec vous de manière intelligente. Il vous maintient également connecté avec toute votre équipe via leurs propres panneaux de contrôle pour suivre leur travail et surveiller leurs tâches.',
        },
        easy: {
          smallText: 'Facile',
          largeText: 'Le système vise à être simple mais efficace en même temps, grâce à son organisation et à sa tabulation de manière simple dans sa version actuelle.',
        },
        helpful: {
          smallText: 'Utile',
          largeText: 'Le système ne se contente pas de vous fournir des outils de gestion, il vise également à vous aider dans votre travail grâce à des outils supplémentaires qui vous aident à améliorer votre connaissance de votre travail, tels qu\'un dictionnaire de médicaments, une encyclopédie des maladies, un modèle tridimensionnel du corps humain, et plus encore...',
        },
        progressed: {
          smallText: 'Progressif',
          largeText: 'Le Système Blanc est un système basé sur le cloud, pas un système installé sur un ordinateur personnel, donc l\'équipe créative de Blanc travaille sur son développement et le rend adapté, confortable et facile à utiliser pour se différencier des systèmes traditionnels "rigides".',
        },
        supported: {
          smallText: 'Pris en charge',
          largeText: 'Le système est fourni avec un guide utilisateur complet et des réponses précises à toutes les questions qui pourraient vous venir à l\'esprit lorsque vous commencez à utiliser le système. Vous n\'aurez pas besoin de contacter un expert pour vous apprendre à utiliser le système, car il y a une explication détaillée de toutes ses fonctions.',
        },
        smart: {
          smallText: 'Intelligent',
          largeText: 'Le système prépare un algorithme pour automatiser les opérations routinières et répétitives, donnant au système l\'attribut d\'intelligence et prenant des décisions routinières basées sur le comportement de l\'utilisateur.',
        },
      },
      subscribeNow: 'Abonnez-vous maintenant et obtenez deux mois gratuits pour essayer le système',
      openVersion: 'Version Ouverte',
      mediumVersion: 'Pour les moyennes entreprises',
      bigVersion: 'Pour les grandes entreprises',
      currencyText: 'USD',
      fullYearText: 'Année complète',
      prosItemList: {
        1: '2 mois gratuits pour essayer le système',
        2: 'Demandes illimitées',
        3: 'Apparition gratuite dans l\'annuaire',
        4: 'Identifiant unique (PID)',
        5: 'Divers outils numériques gratuits',
        6: 'Capacité de se connecter avec les appareils intelligents Blanc',
        7: 'Capacité de se connecter avec une carte bancaire',
        8: 'Analyse intelligente et automatisée des données',
        9: 'Sauvegarde automatique des données',
      },
      subscribeBtn: 'Abonnez-vous maintenant'
    },
    bottomCard:{
      footerVision:'Quelle est la vision de la plateforme Abyad ?',
      footerHow:'Comment utiliser la plateforme ?',
      footerWho:'Qui sommes-nous ?',
    }
  },
    
}
export default FrancaisTrans