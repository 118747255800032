const TunisieTrans = {
    translation : {
      mainPageLanding : {
        mainTitle:' أنظمة أبيض ',
        adsText : {
          mainText:'إن رقمنة العمليات اليومية المختلفة في ميادين الأعمال المتنوعة سيساعد في تطويرها و الإرتقاء بجودتها نحو الأفضل مما يساهم في تقليص للمجهود و ربح للوقت و للتكايف, لذلك  تهدف منصة أبيض إلي تحسين محيط و بيئة الاعمال في تونس في جميع المجالات من خلال العديد من الأنظمة الإدارية . ',
          subText:' توفر منصة أبيض العديد من الأنظمة في مختلف المجالات :'
        },
        categoriesName: {
          ptvente :'أنظمة نقاط البيع :',
          health :'الأنظمة الصحية :',
          education :'الأنظمة التعليمية :',
          restaurant :'أنظمة خدمات الضيافة :',
          transport :'أنظمة خدمات النقل و التنقل :',
          handmade :'أنظمة خدمات حرفية :',
          construction :'أنظمة خدمات عقارية :',
          wedding :'أنظمة خدمات الزواج :',
          sport :'أنظمة رياضية :',
          other :'أنظمة أخري :',
        },
        systemNames : {
            docteur: "نِظَامْ إِدَارًةْ العِيَادَاتْ",
            pharmacie: "نِظَامْ إِدَارًةْ الصَيْدَلِّيَاتْ",
            clinique: "نِظَامْ إِدَارًةْ المِصَحَّاتْ الخَاصَة",
            labo: "نِظَامْ إِدَارًةْ مَخْبَرْ طِبِّي   ",
            infirmier: "نِظَامْ إِدَارًةْ عِيَادَةْ مُمَرِضْ   ",
            centre: "نِظَامْ إِدَارًةْ المَرَاكِزْ الطَبْيَّة",
            admin_s_hospital: "نِظَامْ إِدَارًةْ المُسْتَشْفِيَاتْ",
            admin_s_csb: "نِظَامْ إِدَارًةْ مَرَاكِزْ الصِحَّة ",
            garderie: "نِظَامْ إِدَارًةْ رِيَاضْ الأَطْفَالْ   ",
            ecole: "نِظَامْ إِدَارًةْ المَدَارِسْ الخَاصَّة",
            lycee: "نِظَامْ إِدَارًةْ المَعَاهِدْ الخَاصَّة",
            universite: "نِظَامْ إِدَارًةْ الجَامِعَاتْ الخَاصَّة",
            formation: "نِظَامْ إِدَارًةْ مراكز التكوين الخاص",
            impremerie: "نِظَامْ إِدَارًةْ المَطَابِعْ",
            librairie: "نِظَامْ إِدَارًةْ الكُتُبِيَّاتْ",
            admin_e_centre: "نِظَامْ إِدَارًةْ مراكز التكوين",
            admin_e_biblio: "نِظَامْ إِدَارًةْ المكتبات العمومية",
            admin_e_ecole: "نِظَامْ إِدَارًةْ المدارس العمومية",
            admin_e_lycee: "نِظَامْ إِدَارًةْ المعاهد العمومية",
            admin_e_universite: "نِظَامْ إِدَارًةْ الجامعات العامة",
            admin_e_ss: "نِظَامْ إِدَارًةْ دواوين الخدمات",
            transporteur: "نِظَامْ إِدَارًةْ وكالة نقل ",
            autoecole: "نِظَامْ إِدَارًةْ مدارس السياقة",
            cafe: "نِظَامْ إِدَارًةْ المَقَاهِي",
            restaurant: "نِظَامْ إِدَارًةْ المَطَاعِمْ",
            magazin: "نِظَامْ إِدَارًةْ المَغَازَاتْ",
            boulengerie: "نِظَامْ إِدَارًةْ المَخَابِزْ",
            boucherie: "نِظَامْ إِدَارًةْ بَيْعْ اللُحُومْ",
            fruiterie: "نِظَامْ إِدَارًةْ بَيْعَ الخُضَرْ",
            patesserie: "نِظَامْ إِدَارًةْ بَيْعْ المُرَطَبَاتْ",
            epicerie: "نِظَامْ إِدَارًةْ البَقَّالاَتْ",
            electromenager: "نِظَامْ إِدَارًةْ نقطة بَيْعْ الإِلِكْتُرُونِيَّاتْ",
            meublerie: "نِظَامْ إِدَارًةْ الأَثَاثْ",
            location: "نِظَامْ إِدَارًةْ كِرَاءْ السَيَّارَاتْ",
            parking: "نِظَامْ إِدَارًةْ مَوَاقِفْ السَيَّارَاتْ",
            qiosque: "نِظَامْ إِدَارًةْ مَحَطَّةْ وَقُودْ",
            mecanicien: "نِظَامْ إِدَارًةْ وَرْشَةْ مِيكَانِيكِي",
            courtier: "نِظَامْ إِدَارًةْ مكتب وسيط عقاري",
            contracteur: "نِظَامْ إِدَارًةْ مَكْتَبْ مُقَاوِلْ",
            architecture: "نِظَامْ إِدَارًةْ مَكْتَبْ مُهَنْدِسْ مِعْمَارِي",
            quincaillerie: "نِظَامْ إِدَارًةْ مَحَلْ مُعِدَّاتْ",
            forgeron: "نِظَامْ إِدَارًةْ وَرْشَةْ حَدَّادْ",
            menuisier: "نِظَامْ إِدَارًةْ وَرَشَاتْ النِجَارَةَ",
            peinture: "نِظَامْ إِدَارًةْ شَرِكَاتْ الدِهَانْ",
            electricien: "نِظَامْ إِدَارًةْ وَرْشَةْ تَقْنِي كَهْرَبَائِي",
            plombier: "نِظَامْ إِدَارًةْ وَرْشَة سَبَّاكْ",
            cristalerie: "نِظَامْ إِدَارًةْ ورشة بلور",
            marbrerie: "نِظَامْ إِدَارًةْ وَرْشَة جَلِيزْ وَ رُخَامْ",
            coiffure: "نِظَامْ إِدَارًةْ قَاعَاتْ الحِلاَقَة",
            boutique: "نِظَامْ إِدَارًةْ مَحَلَّاتْ المَلاَبِسْ",
            salle_marriage: "نِظَامْ إِدَارًةْ قَاعَاتْ الأَفْرَاحْ ",
            orchestre: "نِظَامْ إِدَارًةْ فِرْقَة مُوسِيقِيَّة",
            chef: "نِظَامْ إِدَارًةْ مَوَاعِيدْ الطَبَّاخْ",
            photographe: "نِظَامْ إِدَارًةْ وَرْشَة مُصَوِّرْ",
            fourniture_marriage: "نِظَامْ إِدَارًةْ كِرَاءْ لَوَازِمْ الأَفْرَاحْ",
            bijouterie: "نِظَامْ إِدَارًةْ نُقْطَةَ بَيْعْ المُجَوْهَرَاتْ",
            gym: "نِظَامْ إِدَارًةْ قَاعَاتْ الرِيَاضَة",
            pyscine: "نِظَامْ إِدَارًةْ نَوَادِي السِبَاحَة",
            stade: "نِظَامْ إِدَارًةْ المَلاَعِبْ الرِيَاضِيَّة",
            cinema: "نِظَامْ إِدَارًةْ قَاعَاتْ السِنِمَا",
            theatre: "نِظَامْ إِدَارًةْ قَاعَةْ مَسْرَحْ",
            musee: "نِظَامْ إِدَارًةْ المَتَاحِفْ",
            admin_c_mj: "نِظَامْ إِدَارًةْ دُورْ الشَبَابْ ",
            admin_c_mc: "نِظَامْ إِدَارًةْ دُورْ الثَقَافَة",
            admin_c_me: "نِظَامْ إِدَارًةْ نَوَادِي الأَطْفَالْ",
            avocat: "نِظَامْ إِدَارًةْ مَكَاتِبْ المُحَامِينْ",
            depot: "نِظَامْ إِدَارًةْ نِقَاطْ بَيْعْ الجُمْلَة",
            comptable: "نِظَامْ إِدَارًةْ مَكْتِبْ مُحَاسِبْ ",
            socite: "نِظَامْ إِدَارًةْ الشَرِكَاتْ",
            bank: "نِظَامْ إِدَارًةْ البُنُوكْ",
            admin_f_poste: "نِظَامْ إِدَارًةْ مَكَاتِبْ البَرٍيدْ",
            admin_f_rf: "نِظَامْ إِدَارًةْ القَبَاضَاتْ المَالِيَّة",
            veterinaire: "نِظَامْ إِدَارًةْ العيادات البيطرية",
            fourragerie: "نِظَامْ إِدَارًةْ نقاط بيع العلف",
            agritools: "نِظَامْ إِدَارًةْ نقاط بيع الأدوات الفلاحية",
            farmer: "نِظَامْ إِدَارًةْ نقاط بيع الأدوات الفلاحية",
            hotels: "نِظَامْ إِدَارًةْ النُزُلْ ",
            vg_agence: "نِظَامْ إِدَارًةْ وِكَالَاتْ الأَسْفَارْ",
            admin_a_mu: "نِظَامْ إِدَارًةْ العيادات",
            admin_a_police: "نِظَامْ إِدَارًةْ العيادات",
            admin_a_ar: "نِظَامْ إِدَارًةْ العيادات",
            admin_a_court: "نِظَامْ إِدَارًةْ المحاكم",
            admin_a_mosq: "نِظَامْ إِدَارًةْ العيادات",
            agriToolsOld: "نِظَامْ إِدَارًةْ الشَرِكَاتْ"
            
        },
      },
      systemLandingPage:{
        adsText: {
          depot: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم .  كما يقدم لك واجهة رسومية (caisse) لتسجيل مبيعات الشاحنات و طباعة الفواتير.  بالإضافة إلي مجموعة من الأدوات  (مثل واجهة  تسجيل الطلبات    commandes  )  التي تساعدك علي أداء عملك بجودة أفضل',
          magazin: 'نظام إداراة المغازاة و نقاط البيع يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          fruiterie: 'نظام إداراة نقاط  بيع الخضر  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          boucherie: 'نظام إداراة المخابز  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          boulengerie: 'نظام إداراة نقاط بيع اللحوم  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          patesserie: 'نظام إداراة نقاط بيع الحلويات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          epicerie: 'نظام إداراة البقالات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          pharmacie: 'نظام إداراة نقاط الصيدليات يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          librairie: 'نظام إداراة الكتبيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          boutique: 'نظام إداراة نقاط بيع الملابس  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          quincaillerie: 'نظام إداراة Quancaillerie  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          electromenager: 'نظام إداراة نقاط بيع الالكترونيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          meublerie: 'نظام إداراة نقاط ابيع الاثاث  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          docteur: ' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما متصلا مع مرضاك  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
          infirmier: ' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما بإتصال بالمرضي  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات التحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
          clinique: 'نظام إدارة المصحات يساعدك علي تتبع جميع العمليات و الأنشطة التي تحدث داخل مصحتك . كما يساعدك علي متابعة مواعيد العمليات و تقارير يومية لفهم تطور الحالة الصحية للمرضي, بالإضافة لجملة من الأدوات الرقمية مثل واجهة تسجيل حضور فريق العمل و واجهة متابعة مريض معين  ',
          labo: 'يساعدك نظام إدارة المخابر الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
          centre: 'يساعدك نظام إدارة المراكز الطبية علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
          garderie: 'نظام إدارة  رياض الأطفال يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
          autoecole: 'يساعدك نظام إدارة    مدارس تعليم السياقة علي رقمنة جميع العمليات اليومية لتسهيل عملية أرشفتها و ذلك لتبسيط التعامل معها كما يقدم لك مجموعة من الحلول الرقمية للتحكم في إدارة العملاء و فريق العمل بالإضافة لمجموعة من الأدوات الأخري التي تزيد من إنتاجيتك و كفاءة الخدمات التي تقدمها  ',
          ecole: 'نظام إدارة المدارس الخاصة يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
          formation: 'نظام إدارة  مراكز التكوين   يمكنك من  متابعة مختلف الانشطة اللي تصير داخل المؤسسة متاعك , و بالتالي مزيد من التحكم و الإدارة الذكية لجعل عملية التعلم أكثر جودة و تطور . كما يوفر مجموعة من الأدوات الرقمية اللي تساعدك في تحسين جودة المادة التعليمة مثل البرنامج البيداغوجي و غيرها  ',
          lycee: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          universite: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          restaurant: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          cafe: 'نكانك صاحب مطعم أبيض يوفرلك نظام إدارة و مراقبة و متابعة للمطعم وإلا العمل متاعك , من خلال بيئة إفتراضية تخليك ديما متصل بالزبائن متاعك لمعرفة ردود أفعالهم علي الخدمات اللي تقدمها ',
          hotel: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          transporteur: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          vg_agence: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          courtier: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          impremerie: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          avocat: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          comptable: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          menuisier: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          forgeron: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          cristal: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          marbre: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          electricien: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          peinture: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          contracteur: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          architecture: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          parking: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          qiosque: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          location: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          photographe: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          salon_marriage: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          fourniture_marriage: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          coiffure: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          gym: '   نظام إدارة  قاعات الرياضة هو نظام إدارة و  محاسبة و تواصل مع مشتركيك و مراقبة أداءهم و تطور نشاطهم الرياضي من خلال خلق فضاء للتفاعل إفتراضيا مع القاعة لتقييم خدماتها ...',
          pyscine: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          stade: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          cinema: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          theatre: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          farm: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
          socite: 'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل'
                  
        ,
        },
        subscribeTo:'إشترك الأن ',
        goToSystem:'الدخول للنظام ',
        prosConsText:'يتمتع بعدة مزايا ',
        prosConsData:{
          connected:{
            smallText:'متصل',
            largeText:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم',
          },
          easy:{
            smallText:'سهل',
            largeText:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية',
          },
          helpfull:{
            smallText:'معين',
            largeText:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...',
          },
          progressed:{
            smallText:'متطور',
            largeText: 'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"',
          },
          supported:{
            smallText:'مدعوم',
            largeText:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه',
          },
          smart:{
            smallText:'ذكي',
            largeText:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم',
          },
        },
        subscribeNow:'إشترك الآن وأحصل علي شهرين مجانييْن لتجرب النظام ',
        openVersion:'نسخة مفتوحة',
        mediumVersion:'للاعمال المتوسطة',
        bigVersion:'للاعمال الكبري',
        currencyText:'د',
        fullYearText:'سنة كاملة',
        prosItemList : {
          1:' 2 أشهر مجانية لتجريب النظام',
          2:' عدد غير محدود من الطلبات',
          3:' ضهور مجاني في الدليل',
          4:' معرف وحيد (PID)',
          5:' أدوات رقمية متنوعة و مجانية',
          6:'  إمكانية الربط بأجهزة أبيض الذكية',
          7:' إمكانية الربط بالبطاقة البنكية',
          8:' تحليل ذكي و ألي للبيانات',
          9:' حفض آلي للبيانات',
        },
        subscribeBtn:' إشترك الأن'
      },
      bottomCard:{
        footerVision:'ماهي  رؤية منصة أبيض ',
        footerHow:'كيف استعمل المنصة ',
        footerWho:'من نحن ؟ ',
      }
    },
}
export default TunisieTrans